export class AuthVerifyEmailTokenController {
  constructor($location, $routeParams, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.token = $routeParams.token;
    this.ntfsSrvc = NotificationService;
  }

  $onInit() {
    this.verifyEmail();
  }

  verifyEmail() {
    if (!this.token) {
      this.ntfsSrvc.warning('Token is required!');
      return;
    }

    this.isSaving = true;
    this.authSrvc
      .verifyEmailToken(this.token)
      .then(data => {
        if (data.isCodeRequired) {
          this.ngLocationSrvc.path('/verify-email-code');
        } else {
          this.infoMessage = data.message;
        }
      })
      .catch(err => this.ntfsSrvc.warning('Email verification failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthVerifyEmailTokenComponent = {
  template: require('./verify-token.component.pug'),
  controller: AuthVerifyEmailTokenController
};
