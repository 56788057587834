export class PartnerService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.partnersUrl = '/api/partners';
  }

  getPartners(params) {
    return this.ngHttpSrvc
      .get(this.partnersUrl, { params })
      .then(res => res.data);
  }

  getPartnerCount(params) {
    let url = `${this.partnersUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getPartnerOne(params) {
    let url = `${this.partnersUrl}/${params._id}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  getPartnerDashboardRoutes(params) {
    let url = `${this.partnersUrl}/${params._id}/dashboard/routes`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  getPartnerDashboardCharts(params) {
    let url = `${this.partnersUrl}/${params._id}/dashboard/charts`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  getPartnerDashboardLeaderboards(params) {
    let url = `${this.partnersUrl}/${params._id}/dashboard/leaderboards`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createPartner(partner) {
    return this.ngHttpSrvc
      .post(this.partnersUrl, JSON.stringify(partner))
      .then(res => res.data);
  }

  updatePartner(partner) {
    let url = `${this.partnersUrl}/${partner._id}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(partner))
      .then(res => res.data);
  }

  updatePartnerStatus(params) {
    let url = `${this.partnersUrl}/${params._id}/status`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(params))
      .then(res => res.data);
  }

  deletePartner(id) {
    let url = `${this.partnersUrl}/${id}`;
    return this.ngHttpSrvc.delete(url);
  }

  registerCard(id, card) {
    let url = `${this.partnersUrl}/${id}/cards`;
    return this.ngHttpSrvc
      .post(url, JSON.stringify(card))
      .then(res => res.data);
  }

  deleteCard(id, customerId) {
    let url = `${this.partnersUrl}/${id}/cards/${customerId}`;
    return this.ngHttpSrvc
      .delete(url)
      .then(res => res.data);
  }

  charge(id, customerId, amount) {
    let url = `${this.partnersUrl}/${id}/cards/${customerId}/charge`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify({ amount }))
      .then(res => res.data);
  }
}
