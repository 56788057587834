import * as _ from 'lodash';

export function getNgTableParams({ NgTableParams, getData, sorting }) {
  let ngTableParams = new NgTableParams({ sorting }, {
    getData: params => getData(params)
  });
  ngTableParams._settings.counts = [10, 20, 50];
  return ngTableParams;
}

export function extendQueryParams(baseQueryParams, params) {
  let customQueryParams = {
    page: _.get(params, '_params.page', 1) - 1,
    count: _.get(params, '_params.count', 10)
  };
  let sorting = _.chain(params)
    .get('_params.sorting')
    .map((sortDirection, sortBy) => ({ sortBy, sortDirection }))
    .head()
    .value();
  _.extend(customQueryParams, sorting);

  return _.extend(baseQueryParams, customQueryParams);
}
