export class EventService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.eventsUrl = '/api/events';
  }

  getEvents(data) {
    return this.ngHttpSrvc
      .get(this.eventsUrl, { params: data })
      .then(res => res.data);
  }

  getEventCount(params) {
    let url = `${this.eventsUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getEventOne(eventId, params) {
    let url = `${this.eventsUrl}/${eventId}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  getEventOneWithRequests(eventId, params) {
    let url = `${this.eventsUrl}/${eventId}/requests`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createEvent(event) {
    return this.ngHttpSrvc
      .post(this.eventsUrl, JSON.stringify(event))
      .then(res => res.data);
  }

  updateEvent(event) {
    let url = `${this.eventsUrl}/${event._id}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(event))
      .then(res => res.data);
  }

  deleteEvent(eventId) {
    let url = `${this.eventsUrl}/${eventId}`;
    return this.ngHttpSrvc.delete(url);
  }
}
