export class DrivingRiderService {
  constructor($http) {
    this.ngHttpSrvc = $http;
  }

  getDrivingRiders(dcKey, data) {
    let url = this._getBaseUrl(dcKey);
    return this.ngHttpSrvc
      .get(url, { params: data })
      .then(res => res.data);
  }

  createDrivingRider(dcKey, riderData) {
    let url = this._getBaseUrl(dcKey);
    return this.ngHttpSrvc
      .post(url, JSON.stringify(riderData))
      .then(res => res.data);
  }

  updateDrivingRider(dcKey, riderData) {
    let url = this._getBaseUrl(dcKey);
    return this.ngHttpSrvc
      .put(url, JSON.stringify(riderData))
      .then(res => res.data);
  }

  deleteDrivingRider(dcKey) {
    let url = this._getBaseUrl(dcKey);
    return this.ngHttpSrvc.delete(url);
  }

  _getBaseUrl(dcKey) {
    return `/api/drivings/${dcKey}/riders`;
  }
}

