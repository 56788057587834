class PartnerAreaCtrl {
  isAreaValid() {
    this.area.isValid = !this.area.isCustom || (this.area.isCustom && this.area.shape);
    return this.area.isValid;
  }
}

export const PartnerAreaComponent = {
  template: require('./area.component.pug'),
  bindings: {
    area: '<',
    isEditable: '<',
    isSaving: '<',
    radioOneTitle: '@',
    radioTwoTitle: '@',
    hideOptions: '<'
  },
  controller: PartnerAreaCtrl
};
