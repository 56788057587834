import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

const PARTNER_FIELDS = 'orgName orgUrl orgType owner:firstName:lastName created status';

class PartnerListCtrl {
  // eslint-disable-next-line max-params
  constructor($q, $location, Identity, NgTableParams, NotificationService, PartnerService) {
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.currUser = Identity.getUser();
    this.isCurrUserAdmin = Identity.hasRole('admin');
    this.ntfsSrvc = NotificationService;
    this.partnerSrvc = PartnerService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadPartners.bind(this),
      sorting: { orgName: 'asc' }
    });
    this.qp = { fields: PARTNER_FIELDS };
  }

  _loadPartners(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.partnerSrvc.getPartners(qp),
        this.partnerSrvc.getPartnerCount(qp)
      ])
      .then(([partners, partnerCount]) => {
        if (!this.isCurrUserAdmin) {
          if (partners.length === 0) {
            this.ngLocationSrvc.path('/partners/info');
            return;
          }
          if (partners.length > 0) {
            this.ngLocationSrvc.path(`/partners/${partners[0]._id}/dashboard`);
            return;
          }
        }
        _.each(partners, partner => {
          partner.isEditable = this.isCurrUserAdmin || partner.owner === this.currUser.userId;
        });
        params.total(partnerCount);
        this.partners = partners;
        return partners;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load partners', err))
      .finally(() => this.isLoading = false);
  }

  deletePartner(partner) {
    if (!confirm('Are you sure you want to delete this partner?')) {
      return;
    }

    partner.isDeleting = true;
    this.partnerSrvc
      .deletePartner(partner._id)
      .then(() => {
        this.ntfsSrvc.info('Partner deleted successfully');
        _.remove(this.partners, partner);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete partner', err))
      .finally(() => partner.isDeleting = false);
  }
}

export const PartnerListComponent = {
  template: require('./list.component.pug'),
  controller: PartnerListCtrl
};
