export class AuthVerifyPasswordController {
  constructor($location, $routeParams, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
    this.token = $routeParams.token;
  }

  isPasswordsMatch() {
    return this.password === this.passwordConfirm;
  }

  verifyPassword() {
    this.isSaving = true;
    this.authSrvc
      .verifyPassword(this.token, this.password)
      .then(() => {
        this.ntfsSrvc.info('Password change succesfully');
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.warning('Password change failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthVerifyPasswordComponent = {
  template: require('./verify.component.pug'),
  controller: AuthVerifyPasswordController
};
