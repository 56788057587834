const ALERT = 'Please use our mobile app for this function, will have it on the web soon: http://share.carzac.com';

class EventDetailsCtrl {
  constructor($routeParams, Identity, NotificationService, ReturnToService, ClipboardService, EventService) {
    this.currentUser = Identity.getUser();
    this.returnToSrvc = ReturnToService;
    this.ntfsSrvc = NotificationService;
    this.clipboardSrvc = ClipboardService;
    this.eventSrvc = EventService;

    this.direction = 'dest';
    this.eventId = $routeParams.id;
    this.returnTo = `/events/${this.eventId}`;
  }

  $onInit() {
    this._loadEvent();
  }

  _loadEvent() {
    this.isLoading = true;
    return this.eventSrvc
      .getEventOneWithRequests(this.eventId)
      .then(({ event, rideOffers, rideRequests }) => {
        event.venue.location = _.reverse(event.venue.location);
        event.dates = _.map(event.dates, d => ({
          date: moment(d.date, 'YYYY-MM-DD').format('ddd M/D'),
          startTime: moment(d.startTime, 'HH:mm').format('h:mm A'),
          endTime: moment(d.endTime, 'HH:mm').format('h:mm A')
        }));
        if (event.eventUrl &&
           !(_.startsWith(event.eventUrl, 'http://') || _.startsWith(event.eventUrl, 'https://'))
        ) {
          event.eventUrl = 'http://' + event.eventUrl;
        }
        this.event = event;
        this.allRideOffers = rideOffers;
        this.allRideRequests = rideRequests;
        this.allowEdit = _.get(this.currentUser, 'userId') === event.creator;
        this._filterRides();
      })
      .catch(err => this.ntfsSrvc.error('Unable to load data', err))
      .finally(() => this.isLoading = false);
  }

  changDirection(direction) {
    this.direction = direction;
    this._filterRides();
  }

  offerRide() {
    this.returnToSrvc.navigateAndExtendPath(`/events/${this.eventId}/ride-offer`, this.returnTo);
  }

  lookForSeat() {
    this.returnToSrvc.navigateAndExtendPath(`/events/${this.eventId}/ride-request`, this.returnTo);
  }

  share() {
    let shareLink = window.location.origin + `/#/events/${this.eventId}`;
    this.clipboardSrvc.copy(shareLink);
    alert('Copied to clipboard!');
  }

  editEvent() {
    this.returnToSrvc.navigateAndExtendPath(`/events/${this.eventId}/edit`, this.returnTo);
  }

  showEvents() {
    this.returnToSrvc.navigateAndExtendPath('/events', this.returnTo);
  }

  chat() {
    alert(ALERT);
  }

  call() {
    alert(ALERT);
  }

  bookSeat() {
    alert(ALERT);
  }

  lookForRide() {
    alert(ALERT);
  }


  _filterRides() {
    this.rideOffers = _.filter(this.allRideOffers, { direction: this.direction });
    this.rideRequests = _.filter(this.allRideRequests,
      req => req.rideRequest.direction === 'roundtrip' || req.rideRequest.direction === this.direction
    );
  }
}

export const EventDetailsComponent = {
  template: require('./details.component.pug'),
  controller: EventDetailsCtrl
};
