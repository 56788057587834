export class PartnerIncentiveService {
  constructor($http) {
    this.ngHttpSrvc = $http;
  }

  getPartnerIncentives(partnerId, params) {
    let url = partnerId ? this._getBaseUrl(partnerId) : '/api/incentives';
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  getPartnerIncentiveCount(partnerId, params) {
    let url = partnerId ? `${this._getBaseUrl(partnerId)}/count` : '/api/incentives/count';
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getPartnerIncentiveOne(partnerId, params) {
    let url = `${this._getBaseUrl(partnerId)}/${params._id}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createPartnerIncentive(partnerId, incentiveData) {
    return this.ngHttpSrvc
      .post(this._getBaseUrl(partnerId), JSON.stringify(incentiveData))
      .then(res => res.data);
  }

  updatePartnerIncentive(partnerId, incentive) {
    let url = `${this._getBaseUrl(partnerId)}/${incentive._id}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(incentive))
      .then(res => res.data);
  }

  updatePartnerIncentiveStatus(partnerId, incentive) {
    let url = `${this._getBaseUrl(partnerId)}/${incentive._id}/status`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(incentive))
      .then(res => res.data);
  }

  deletePartnerIncentive(partnerId, incentiveId) {
    let url = `${this._getBaseUrl(partnerId)}/${incentiveId}`;
    return this.ngHttpSrvc.delete(url);
  }

  _getBaseUrl(partnerId) {
    return `/api/partners/${partnerId}/incentives`;
  }
}
