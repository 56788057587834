import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

class PartnerIncentiveListCtrl {
  // eslint-disable-next-line max-params
  constructor($q, $location, Identity, NgTableParams, NotificationService, PartnerIncentiveService) {
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.currUser = Identity.getUser();
    this.isAdmin = Identity.hasRole('admin');
    this.ntfsSrvc = NotificationService;
    this.incentiveSrvc = PartnerIncentiveService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadIncentives.bind(this),
      sorting: { name: 'asc' }
    });
  }

  _loadIncentives(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.incentiveSrvc.getPartnerIncentives(this.partnerId, qp),
        this.incentiveSrvc.getPartnerIncentiveCount(this.partnerId, qp)
      ])
      .then(([incentives, incentiveCount]) => {
        params.total(incentiveCount);
        this.incentives = incentives;
        return incentives;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load incentives', err))
      .finally(() => this.isLoading = false);
  }

  deletePartnerIncentive(incentive) {
    if (!confirm('Are you sure you want to delete this incentive?')) {
      return;
    }

    incentive.isDeleting = true;
    this.incentiveSrvc
      .deletePartnerIncentive(incentive.partner, incentive._id)
      .then(() => {
        this.ntfsSrvc.info('Incentive deleted successfully');
        _.remove(this.incentives, incentive);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete incentive', err))
      .finally(() => incentive.isDeleting = false);
  }
}

export const PartnerIncentiveListComponent = {
  template: require('./list.component.pug'),
  controller: PartnerIncentiveListCtrl,
  bindings: {
    partnerId: '<'
  }
};
