export class ActivityService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.activitiesUrl = '/api/activities';
  }

  getActivities(data) {
    return this.ngHttpSrvc
      .get(this.activitiesUrl, { params: data })
      .then(res => res.data);
  }

  getActivityCount(params) {
    let url = `${this.activitiesUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getActivityOne(activityId, params) {
    let url = `${this.activitiesUrl}/${activityId}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createActivity(activity) {
    return this.ngHttpSrvc
      .post(this.activitiesUrl, JSON.stringify(activity))
      .then(res => res.data);
  }

  updateActivity(activity) {
    let url = `${this.activitiesUrl}/${activity.activityId}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(activity))
      .then(res => res.data);
  }

  deleteActivity(activityId) {
    let url = `${this.activitiesUrl}/${activityId}`;
    return this.ngHttpSrvc.delete(url);
  }

  updateActivityProgram(activityId, programId, program) {
    let url = `${this.activitiesUrl}/${activityId}/${programId}`;
    return this.ngHttpSrvc.put(url, JSON.stringify(program));
  }

  deleteActivityProgram(activityId, programId) {
    let url = `${this.activitiesUrl}/${activityId}/${programId}`;
    return this.ngHttpSrvc.delete(url);
  }

  getActivityWithUserRequests(activityId, params) {
    let url = `api/activities/${activityId}/users`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  updateActivityUserRequest(activityId, params) {
    let url = `/activities/${activityId}/users`;
    return this.ngHttpSrvc.put(url, JSON.stringify(params));
  }

  deleteActivityUserRequest(activityId, params) {
    let url = `/activities/${activityId}/users`;
    return this.ngHttpSrvc.delete(url, { params });
  }

  getActivityWithUsersBgCheck(activityId, params) {
    let url = `api/activities/${activityId}/users/bg-check`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  updateActivityUserBgCheck(activityId, params) {
    let url = `/activities/${activityId}/users/bg-check`;
    return this.ngHttpSrvc.put(url, JSON.stringify(params));
  }

  deleteActivityUserBgCheck(activityId, params) {
    let url = `/activities/${activityId}/users/bg-check`;
    return this.ngHttpSrvc.delete(url, { params });
  }
}
