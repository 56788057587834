import { HomeComponent } from './home.component';

angular
  .module('czApp.home', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/', {
        template: '<home></home>'
      });
  })
  .component('home', HomeComponent);
