export class DrivingService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.drivingsUrl = '/api/drivings';
  }

  getDrivings(data) {
    return this.ngHttpSrvc
      .get(this.drivingsUrl, { params: data })
      .then(res => res.data);
  }

  getDrivingCount(params) {
    let url = `${this.drivingsUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getDrivingOne({ dcKey, params }) {
    let url = `${this.drivingsUrl}/${dcKey}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  calcDrivingPriceAndDistance({ dcKey, params }) {
    let url = `${this.drivingsUrl}/${dcKey}/price`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createDriving(driving) {
    return this.ngHttpSrvc
      .post(this.drivingsUrl, JSON.stringify(driving))
      .then(res => res.data);
  }

  updateDriving(driving) {
    let url = `${this.drivingsUrl}/${driving.dcKey}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(driving))
      .then(res => res.data);
  }

  cancelDriving(dcKey) {
    let url = `${this.drivingsUrl}/${dcKey}/cancel`;
    return this.ngHttpSrvc
      .post(url)
      .then(res => res.data);
  }

  deleteDriving(dcKey) {
    let url = `${this.drivingsUrl}/${dcKey}`;
    return this.ngHttpSrvc.delete(url);
  }
}
