export const SF_CENTER = [37.7749, -122.4194];

export function convertAreaToGmapsFormat(area) {
  area.paths = _.chain(area.paths)
    .chunk(2)
    .map(coords => _.reverse(coords))
    .value();
}

export function convertAreaToMongodbFormat(area) {
  area.paths = _.chain(area.paths)
    .map(coords => _.reverse(coords))
    .flatten()
    .value();
}

export function convertAudienceForEdit(audience) {
  audience.isValid = true;
  audience.domains = _.map(audience.domains, domain => ({ value: domain }));
}

export function convertAudienceForSave(audience) {
  audience.domains = _.map(audience.domains, 'value');
}

export function getAreaCenter(area) {
  return _.get(area, 'paths[0]', SF_CENTER);
}
