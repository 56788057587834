import {
  convertAreaToGmapsFormat,
  convertAreaToMongodbFormat,
  convertAudienceForEdit,
  convertAudienceForSave
} from '../_core/partner.helpers';

class PartnerFormCtrl {
  constructor($routeParams, $location, Identity, NotificationService, PartnerService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.partnerSrvc = PartnerService;
    this.partnerId = $routeParams.partnerId;
    this.isAdmin = Identity.hasRole('admin');
  }

  $onInit() {
    this._loadPartner();
  }

  _loadPartner() {
    if (!this.partnerId) {
      this.partner = {
        mailingAddress: {},
        billingAddress: {},
        area: {},
        audience: {
          isCustom: false,
          isValid: true,
          domains: []
        },
        isEditable: true
      };
      return;
    }

    this.isLoading = true;
    return this.partnerSrvc
      .getPartnerOne({ _id: this.partnerId })
      .then(partner => {
        convertAreaToGmapsFormat(partner.area);
        convertAudienceForEdit(partner.audience);
        this.partner = partner;
        this.changeAddress('mailing');
        this.changeAddress('billing');
      })
      .catch(err => this.ntfsSrvc.error('Unable to load partner', err))
      .finally(() => this.isLoading = false);
  }

  changePartnerStatus() {
    let newStatus;
    switch (this.partner.status) {
      case 'pending approval':
        newStatus = 'approved';
        break;
      case 'approved':
        newStatus = 'pending approval';
        break;
    }

    this.isSaving = true;
    return this.partnerSrvc
      .updatePartnerStatus({
        _id: this.partnerId,
        status: newStatus
      })
      .then(res => {
        this.ntfsSrvc.info('Partner status updated successfully');
        this.partner.status = res.status;
      })
      .catch(err => this.ntfsSrvc.error('Unable to update partner status', err))
      .finally(() => this.isSaving = false);
  }

  changeAddress(addressType) {
    this[addressType + 'Address'] = _.reduce(this.partner[addressType + 'Address'], (result, value) => {
      if (value) {
        result += value + ' ';
      }
      return result;
    }, '');
  }

  savePartner() {
    this.isSaving = true;
    let partnerCopy = _.cloneDeep(this.partner);
    convertAreaToMongodbFormat(partnerCopy.area);
    convertAudienceForSave(partnerCopy.audience);

    let fnName = partnerCopy._id ? 'updatePartner' : 'createPartner';
    return this.partnerSrvc[fnName](partnerCopy)
      .then(partner => {
        this.partner.status = partner.status;
        this.ntfsSrvc.info('Partner saved successfully');
        this.ngLocationSrvc.path(`/partners/${partner._id}/edit`);
      })
      .catch(err => this.ntfsSrvc.error('Unable to save partner', err))
      .finally(() => this.isSaving = false);
  }
}

export const PartnerFormComponent = {
  template: require('./form.component.pug'),
  controller: PartnerFormCtrl
};
