export function getEventActualDate(eventDates) {
  let now = new Date();
  let eventActualDate = _.find(eventDates, date => date.fullStartDate < now && now < date.fullEndDate);
  return eventActualDate || eventDates[eventDates.length - 1];
}

export function getLocationFromEvent(event) {
  return {
    name: _.get(event, 'venue.name'),
    address: _.get(event, 'venue.address'),
    city: _.get(event, 'venue.city'),
    state: _.get(event, 'venue.state'),
    zip: _.get(event, 'venue.zip'),
    latitude: _.get(event, 'venue.latitude'),
    longitude: _.get(event, 'venue.longitude')
  };
}

export function getRequestForSave(request, event) {
  return _getOfferOrRequestForSave(request, event);
}

export function getOfferForSave(offer, event) {
  return _getOfferOrRequestForSave(offer, event);
}

function _getOfferOrRequestForSave(model, event) {
  let modelCopy = _.cloneDeep(model);
  modelCopy.date = moment(modelCopy.date).format('YYYY-MM-DD');
  modelCopy.time = moment(modelCopy.time).format('HH:mm');
  modelCopy.returnDate = moment(modelCopy.returnDate).format('YYYY-MM-DD');
  modelCopy.returnTime = moment(modelCopy.returnTime).format('HH:mm');

  if (_.isNil(modelCopy.requirements.bags)) {
    delete modelCopy.requirements.bags;
  }
  if (_.isNil(modelCopy.requirements.bikes)) {
    delete modelCopy.requirements.bikes;
  }
  if (_.isNil(modelCopy.requirements.skis)) {
    delete modelCopy.requirements.skis;
  }

  modelCopy.to = getLocationFromEvent(event);
  switch (modelCopy.direction) {
    case 'there':
      delete modelCopy.returnSeats;
      delete modelCopy.returnDate;
      delete modelCopy.returnTime;
      break;
    case 'back':
      modelCopy.seats = modelCopy.returnSeats;
      modelCopy.date = modelCopy.returnDate;
      modelCopy.time = modelCopy.returnTime;
      delete modelCopy.returnSeats;
      delete modelCopy.returnDate;
      delete modelCopy.returnTime;
      break;
    case 'roundtrip':
      break;
  }

  return modelCopy;
}
