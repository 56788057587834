export class AuthVerifyEmailCodeController {
  constructor($routeParams, AuthService, NotificationService) {
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
    this.token = $routeParams.token;
  }

  verifyCode() {
    this.isSaving = true;
    this.authSrvc
      .verifyEmailCode(this.token, this.code)
      .then(() => this.infoMessage = 'Email verified succesfully')
      .catch(err => this.ntfsSrvc.warning('Email verification failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthVerifyEmailCodeComponent = {
  template: require('./verify-code.component.pug'),
  controller: AuthVerifyEmailCodeController
};
