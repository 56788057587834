import { LeaderboardService } from './service';
import { LeaderboardListComponent } from './list.component';

import './styles.styl';

angular
  .module('czApp.leaderboards', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/leaderboards', {
        template: '<leaderboard-list></leaderboard-list>'
      });
  })
  .service('LeaderboardService', LeaderboardService)
  .component('leaderboardList', LeaderboardListComponent);
