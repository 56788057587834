class EventCardCtrl {
  constructor(NotificationService, EventService) {
    this.ntfsSrvc = NotificationService;
    this.eventSrvc = EventService;
  }

  $onChanges(changes) {
    let eventId = _.get(changes, 'eventId.currentValue');
    if (eventId) {
      this._loadEvent(eventId);
    }
  }

  _loadEvent(eventId) {
    this.isLoading = true;
    return this.eventSrvc
      .getEventOne(eventId)
      .then(event => {
        let firstDate = _.get(event, 'dates[0].date');
        if (firstDate) {
          event.date = moment(firstDate, 'YYYY-MM-DD').toDate();
        }
        this.event = event;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load event', err))
      .finally(() => this.isLoading = false);
  }
}

export const EventCardComponent = {
  template: require('./card.component.pug'),
  controller: EventCardCtrl,
  bindings: {
    eventId: '<'
  }
};
