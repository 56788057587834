class RouteListController {
  // eslint-disable-next-line max-statements
  constructor($routeParams, NotificationService, RouteService) {
    this.ntfsSrvc = NotificationService;
    this.routeSrvc = RouteService;

    this.userId = $routeParams.userId;
    this.routeId = $routeParams.routeId;
    this.placeId = $routeParams.placeId;

    this.isLoading = true;
    this.routes = [];
    this.currentPage = 1;
    this.docsPerPage = 10;
    this.docCount = 0;
    this.reverse = false;
  }

  $onInit() {
    this.loadRoutes();
  }

  loadRoutes() {
    this.isLoading = true;
    this.routeSrvc
      .query({
        userId: this.userId,
        routeId: this.routeId,
        placeId: this.placeId,
        page: this.currentPage,
        pageSize: this.docsPerPage,
        search: this.searchPhrase,
        homeCity: this.homeCity,
        workCity: this.workCity,
        warnings: this.warningsFilter
      })
      .$promise
      .then(results => {
        _.each(results.routes, route => {
          route.matchedDriversCount = route.matchedDrivers.length;
          route.regularWarns = _.chain(route.warnings)
            .filter(warn => warn.level < 3)
            .take(5)
            .map(warn => '- ' + warn.message)
            .join('\n')
            .value();
          route.criticalWarns = _.chain(route.warnings)
            .filter(warn => warn.level >= 3)
            .take(5)
            .map(warn => '- ' + warn.message)
            .join('\n')
            .value();
          if (route.regularWarns.length >= 5) {
            route.regularWarns += '\n...and more';
          }
          if (route.criticalWarns.length >= 5) {
            route.criticalWarns += '\n...and more';
          }
        });
        this.docCount = results.count;
        this.routes = results.routes;
      })
      .catch(err => {
        this.ntfsSrvc.error('Unable to load routes', err);
        this.docCount = 0;
        this.routes = [];
      })
      .finally(() => this.isLoading = false);
  }

  setWarningsFilter(warningsFilter) {
    this.warningsFilter = warningsFilter;
    this.loadRoutes();
  }

  toggleStatus(route) {
    if (route.status === 'active') {
      this.routeSrvc
        .pause({ routeId: route.routeId })
        .$promise
        .then(() => route.status = 'suspended')
        .catch(err => this.ntfsSrvc.error('Unable to change route status', err));
    } else {
      this.routeSrvc
        .resume({ routeId: route.routeId })
        .$promise
        .then(() => route.status = 'active')
        .catch(err => this.ntfsSrvc.error('Unable to change route status', err));
    }
  }

  deleteRoute(route) {
    this.routeSrvc
      .delete({ routeId: route.routeId })
      .$promise
      .then(() => {
        this.ntfsSrvc.info('Route is deleted');
        return this.loadRoutes();
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete route', err));
  }

  deleteHomePlace(placeId, routeId) {
    this.routeSrvc
      .deleteHomePlace({ routeId, placeId })
      .$promise
      .then(() => {
        this.ntfsSrvc.info('Route homePlace is deleted');
        return this.loadRoutes();
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete route HomePlace', err));
  }

  deleteWorkPlace(placeId, routeId) {
    this.routeSrvc
      .deleteWorkPlace({ routeId, placeId })
      .$promise
      .then(() => {
        this.ntfsSrvc.info('Route WorkPlace is deleted');
        return this.loadRoutes();
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete route WorkPlace', err));
  }

  getStartDocument() {
    return (this.currentPage - 1) * this.docsPerPage + 1;
  }

  getEndDocument() {
    return Math.min(this.docCount, this.currentPage * this.docsPerPage);
  }

  getPageCount() {
    return Math.ceil(this.docCount / this.docsPerPage);
  }

  openPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadRoutes();
    }
  }

  openNextPage() {
    if (this.currentPage < this.getPageCount()) {
      this.currentPage++;
      this.loadRoutes();
    }
  }

  orderBy(predicate) {
    if (this.predicate === predicate) {
      this.reverse = !this.reverse;
    }
    this.routes = _.orderBy(this.routes, predicate, this.reverse ? 'desc' : 'asc');
    this.predicate = predicate;
  }
}

export const RouteListComponent = {
  template: require('./list.component.pug'),
  controller: RouteListController
};
