export function UserService($resource) {
  return $resource('/api/admin/users/:id', { id: '@_id' }, {
    get: {
      method: 'GET'
    },
    getReport: {
      method: 'GET',
      url:'/api/admin/bgcheck/:id/report',
      isArray: false
    },
    getRegular: {
      url: '/api/users/:id',
      method: 'GET'
    },
    updateRegular: {
      url: '/api/users/:id',
      method: 'PUT'
    },
    delete: {
      method: 'DELETE'
    },
    query: {
      method: 'GET'
    }
  });
}
