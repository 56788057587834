class CardFormCtrl {
  constructor($timeout) {
    this.timeoutSrvc = $timeout;

    this.card = {
      number: ''
    };
    this.options = {
      creditCard: true,
      onCreditCardTypeChanged: this.onCreditCardTypeChanged.bind(this)
    };
  }

  onCreditCardTypeChanged(type) {
    this.timeoutSrvc(() => {
      if (type !== 'unknown') {
        this.card.type = type;
      } else if (this.card.number) {
        this.card.type = type;
      } else {
        this.card.type = '';
      }
    }, 500);
  }

  register() {
    this.onRegistered({ card: this.card });
  }

  cancel() {
    this.onRegistered({ card: null });
  }
}

export const CardFormComponent = {
  template: require('./card-form.component.pug'),
  bindings: {
    onRegistered: '&',
    isDisabled: '<'
  },
  controller: CardFormCtrl
};
