class UserDetailsController {
  constructor($q, $routeParams, NotificationService, UserService, UserServiceV2, RouteService) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.userSrvc = UserService;
    this.userSrvcV2 = UserServiceV2;
    this.routesSrvc = RouteService;
    this.userId = $routeParams.userId;
  }

  $onInit() {
    this.loadUser();
  }

  loadUser() {
    this.isLoading = true;
    this.ngQSrvc
      .all([
        this.userSrvc.get({ id: this.userId }).$promise,
        this.routesSrvc.query({ userId: this.userId }).$promise
      ])
      .then(([user, routes]) => {
        this.user = user;
        this.routes = routes.routes;
      })
      .catch(err => this.ntfsSrvc.warning('Unable to user details', err))
      .finally(() => this.isLoading = false);
  }

  deleteUser() {
    if (confirm('Are you sure you want to delete this user?')) {
      this.userSrvc.delete({ id: this.userId }, data => this.user = data);
    }
  }

  loadDriverReport() {
    if (this.report) {
      return;
    }
    this.isDriverReportLoading = true;
    this.userSrvc
      .getReport({ id: this.userId }).$promise
      .then(report => this.report = report)
      .catch(err => this.ntfsSrvc.warning('Unable to load report', err))
      .finally(() => this.isDriverReportLoading = false);
  }

  loadFriends() {
    if (this.friends) {
      return;
    }
    this.isFriendsLoading = true;
    this.userSrvcV2
      .getFriends(this.userId)
      .then(friends => this.friends = friends)
      .catch(err => this.ntfsSrvc.warning('Unable to load friends list', err))
      .finally(() => this.isFriendsLoading = false);
  }

  updateFriendship(friend, status) {
    friend.isSaving = true;
    this.userSrvcV2
      .updateFriendship(
        this.userId, {
          friendId: friend.userId,
          status
        }
      )
      .then(res => friend.status = res.status)
      .catch(err => this.ntfsSrvc.warning('Unable to change friend status', err))
      .finally(() => friend.isSaving = false);
  }
}

export const UserDetailsComponent = {
  template: require('./details.component.pug'),
  controller: UserDetailsController
};
