class DrivingRiderFormController {
  consturctor($routeParams, $location, NotificationService, DrivingRiderService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.drivingRiderSrvc = DrivingRiderService;
    this.dcKey = $routeParams.dcKey;
    this.rider = {
      route: '58d9673447ff43001182305c',
      pickupTime: '12:15',
      pickupPlace: '59664ee969a81545894feb56',
      dropoffPlace: '59664ee969a81545894feb57',
      price: 15.5
    };
    this.isNew = true; // TODO?
  }

  $onInit() {
    /* TODO: load rider
    if (dcKey) {
      NotificationService.info('Loading rider...');
      drivingsSrvc
        .getRiderOne({ dcKey })
        .then(driving => {
          NotificationService.info('Driving is loaded');
          this.driving = driving;
        })
        .catch(err => NotificationService.error('Error on loading rider', err));
    } else {
      this.driving = {};
    }*/
  }

  save() {
    let fn = this.isNew ?
      this.drivingRiderSrvc.createDrivingRider.bind(this.drivingRiderSrvc) :
      this.drivingRiderSrvc.updateDrivingRider.bind(this.drivingRiderSrvc);
    fn(this.dcKey, this.rider)
      .then(() => {
        this.ntfsSrvc.info('Rider is saved');
        this.ngLocationSrvc.path('/drivings'); // TODO: where to navigate
      })
      .catch(err => this.ntfsSrvc.error('Error on saving rider', err));
  }
}

export const DrivingRiderFormComponent = {
  template: require('./form.component.pug'),
  controller: DrivingRiderFormController
};
