class PartnerInfoCtrl {
  constructor(Identity) {
    this.isCreatePartnerButtonVisible = Identity.isAuthenticated();
  }
}

export const PartnerInfoComponent = {
  template: require('./info.component.pug'),
  controller: PartnerInfoCtrl,
  bindings: {
    isInjected: '<'
  }
};
