import { AuthService } from './service';
import { AuthSignupComponent } from './signup.component';
import { AuthSigninComponent } from './signin.component';
import { AuthMobileLoginComponent } from './mobile-login/login.component';
import { AuthMobileLoginVerificationComponent } from './mobile-login/verification.component';
import { AuthMobileLoginUserDetailsComponent } from './mobile-login/user-details.component';
import { AuthTermsComponent } from './terms.component';
import { AuthResetPasswordComponent } from './password-updater/reset.component';
import { AuthVerifyPasswordComponent } from './password-updater/verify.component';
import { AuthChangeEmailComponent } from './email-updater/change.component';
import { AuthVerifyEmailTokenComponent } from './email-updater/verify-token.component';
import { AuthVerifyEmailCodeComponent } from './email-updater/verify-code.component';
import { AuthChangePhonenumberComponent } from './phonenumber-updater/change.component';
import { AuthVerifyPhonenumberCodeComponent } from './phonenumber-updater/verify-code.component';
import { AuthVerifyPhonenumberTokenComponent } from './phonenumber-updater/verify-token.component';

import './auth.styl';

angular
  .module('czApp.auth', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/signup', {
        template: '<auth-signup></auth-signup>'
      })
      .when('/signin', {
        template: '<auth-signin></auth-signin>'
      })
      .when('/mobile-login', {
        template: '<auth-mobile-login></auth-mobile-login>'
      })
      .when('/mobile-login/verification', {
        template: '<auth-mobile-login-verification></auth-mobile-login-verification>'
      })
      .when('/mobile-login/user-details', {
        template: '<auth-mobile-login-user-details></auth-mobile-login-user-details>'
      })
      .when('/terms', {
        template: '<auth-terms></auth-terms>'
      })
      .when('/reset-password', {
        template: '<auth-reset-password></auth-reset-password>'
      })
      .when('/verify-password', {
        template: '<auth-verify-password></auth-verify-password>'
      })
      .when('/change-email', {
        template: '<auth-change-email></auth-change-email>'
      })
      .when('/verify-email-token', {
        template: '<auth-verify-email-token></auth-verify-email-token>'
      })
      .when('/verify-email-code', {
        template: '<auth-verify-email-code></auth-verify-email-code>'
      })
      .when('/change-phonenumber', {
        template: '<auth-change-phonenumber></auth-change-phonenumber>'
      })
      .when('/verify-phonenumber-code', {
        template: '<auth-verify-phonenumber-code></auth-verify-phonenumber-code>'
      })
      .when('/verify-phonenumber-token', {
        template: '<auth-verify-phonenumber-token></auth-verify-phonenumber-token>'
      });
  })
  .service('AuthService', AuthService)
  .component('authSignup', AuthSignupComponent)
  .component('authSignin', AuthSigninComponent)
  .component('authMobileLogin', AuthMobileLoginComponent)
  .component('authMobileLoginVerification', AuthMobileLoginVerificationComponent)
  .component('authMobileLoginUserDetails', AuthMobileLoginUserDetailsComponent)
  .component('authTerms', AuthTermsComponent)
  .component('authResetPassword', AuthResetPasswordComponent)
  .component('authVerifyPassword', AuthVerifyPasswordComponent)
  .component('authChangeEmail', AuthChangeEmailComponent)
  .component('authVerifyEmailToken', AuthVerifyEmailTokenComponent)
  .component('authVerifyEmailCode', AuthVerifyEmailCodeComponent)
  .component('authChangePhonenumber', AuthChangePhonenumberComponent)
  .component('authVerifyPhonenumberCode', AuthVerifyPhonenumberCodeComponent)
  .component('authVerifyPhonenumberToken', AuthVerifyPhonenumberTokenComponent);
