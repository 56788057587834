export class MajorDestService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.majorDestsUrl = '/api/major-dests';
  }

  getMajorDests(params) {
    return this.ngHttpSrvc
      .get(this.majorDestsUrl, { params })
      .then(res => res.data);
  }

  getMajorDestCount(params) {
    let url = `${this.majorDestsUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getMajorDestOne(params) {
    let url = `${this.majorDestsUrl}/${params._id}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createMajorDest(majorDest) {
    return this.ngHttpSrvc
      .post(this.majorDestsUrl, JSON.stringify(majorDest))
      .then(res => res.data);
  }

  updateMajorDest(majorDest) {
    let url = `${this.majorDestsUrl}/${majorDest._id}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(majorDest))
      .then(res => res.data);
  }

  deleteMajorDest(id) {
    let url = `${this.majorDestsUrl}/${id}`;
    return this.ngHttpSrvc.delete(url);
  }
}
