import { DrivingRiderService } from './service';
import { DrivingRiderListComponent } from './list.component';
import { DrivingRiderFormComponent } from './form.component';
import { DrivingRiderBookSeatComponent } from './book-seat.component';

import './styles.styl';

angular
  .module('czApp.driving-riders', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/drivings/:dcKey/riders', {
        template: '<driving-rider-list></driving-rider-list>'
      })
      .when('/drivings/:dcKey/riders/new', {
        template: '<driving-rider-form></driving-rider-form>'
      })
      .when('/drivings/:dcKey/riders/edit', {
        template: '<driving-rider-form></driving-rider-form>'
      })
      .when('/drivings/:dcKey/riders/book-seat', {
        template: '<driving-rider-book-seat></driving-rider-book-seat>'
      });
  })
  .service('DrivingRiderService', DrivingRiderService)
  .component('drivingRiderList', DrivingRiderListComponent)
  .component('drivingRiderForm', DrivingRiderFormComponent)
  .component('drivingRiderBookSeat', DrivingRiderBookSeatComponent);
