export class AuthVerifyPhonenumberCodeController {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  verifyCode() {
    this.isSaving = true;
    this.authSrvc
      .verifyPhonenumberCode(this.code)
      .then(data => {
        this.ntfsSrvc.info(data.message);
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.warning('Phone number verification failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthVerifyPhonenumberCodeComponent = {
  template: require('./verify-code.component.pug'),
  controller: AuthVerifyPhonenumberCodeController
};
