import { ActivityService } from './service';
import { ActivityListComponent } from './list.component';
import { ActivityFormComponent } from './form.component';
import { ActivityDetailsComponent } from './details.component';
import { ActivityStatusComponent } from './status.component';
import { ActivityAdminDashComponent } from './admin-dash.component';
import { ActivityBgCheckDashComponent } from './bg-check-dash.component';

import './styles.styl';

angular
  .module('czApp.activities', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/activities', {
        template: '<activity-list></activity-list>'
      })
      .when('/activities/new', {
        template: '<activity-form></activity-form>'
      })
      .when('/activities/:id', {
        template: '<activity-details></activity-details>'
      })
      .when('/activities/:id/edit', {
        template: '<activity-form></activity-form>'
      })
      .when('/activities/:id/admin-dash', {
        template: '<activity-admin-dash></activity-admin-dash>'
      })
      .when('/activities/:id/bg-check-dash', {
        template: '<activity-bg-check-dash></activity-bg-check-dash>'
      });
  })
  .service('ActivityService', ActivityService)
  .component('activityList', ActivityListComponent)
  .component('activityForm', ActivityFormComponent)
  .component('activityDetails', ActivityDetailsComponent)
  .component('activityStatus', ActivityStatusComponent)
  .component('activityAdminDash', ActivityAdminDashComponent)
  .component('activityBgCheckDash', ActivityBgCheckDashComponent);
