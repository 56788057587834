export class AuthMobileLoginCtrl {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  login() {
    this.isSaving = true;
    this.authSrvc
      .mobileLogin(this.rawPhoneNumber, this.countryAlphaCode)
      .then(res => {
        this.ntfsSrvc.info('We sent verification code to your phone number');
        this.ngLocationSrvc.search({ token: res.data.token });
        this.ngLocationSrvc.path('/mobile-login/verification');
      })
      .catch(err => this.ntfsSrvc.warning('Unable to register your phone number', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthMobileLoginComponent = {
  template: require('./login.component.pug'),
  controller: AuthMobileLoginCtrl
};
