class NavBarController {
  constructor($location, Identity, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.identity = Identity;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  isAuthenticated() {
    return this.identity.isAuthenticated();
  }

  isAdmin() {
    return this.identity.hasRole('admin');
  }

  isUser() {
    return this.identity.hasRole('user');
  }

  isActive(route) {
    return _.startsWith(this.ngLocationSrvc.path(), route);
  }

  getUserName() {
    let user = this.identity.getUser();
    return user ? user.name : '';
  }

  signout() {
    return this.authSrvc
      .signout()
      .then(() => {
        this.ntfsSrvc.info('You\'re logged out');
        this.ngLocationSrvc.path('/signin');
      });
  }
}

export const NavBarComponent = {
  template: require('./navbar.component.pug'),
  controller: NavBarController
};
