class ActivityAdminDashCtrl {
  constructor($routeParams, Identity, NotificationService, ActivityService) {
    this.currentUser = Identity.getUser();
    this.ntfsSrvc = NotificationService;
    this.activitySrvc = ActivityService;

    this.activityId = $routeParams.id;
    this.returnTo = '/activities';
  }

  $onInit() {
    this._loadActivity();
  }

  _loadActivity() {
    this.isLoading = true;
    return this.activitySrvc
      .getActivityWithUserRequests(this.activityId)
      .then(({ activity, users }) => {
        this.activity = activity;
        this.allUsers = users;
        this.filterUsersByStatus('pending');
      })
      .catch(err => this.ntfsSrvc.error('Unable to load activity', err))
      .finally(() => this.isLoading = false);
  }

  updateUserRequestStatus(user, newStatus) {
    this
      ._updateUserRequest(user, { userId: user.userId, status: newStatus })
      .then(() => {
        user.status = newStatus;
        this._filterUsers();
      });
  }

  updateUserRequestNote(user) {
    this._updateUserRequest(user, { userId: user.userId, note: user.note });
  }

  deleteUserRequest(user) {
    if (!confirm('Are you sure you want to delete the user’s request ?')) {
      return;
    }
    user.isSaving = true;
    this.activitySrvc
      .deleteActivityUserRequest(this.activityId, { userId: user.userId })
      .then(() => {
        _.remove(this.allUsers, user);
        this._filterUsers();
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete user\'s request', err))
      .finally(() => user.isSaving = false);
  }

  filterUsersByStatus(status) {
    this.selectedStatus = status;
    this._filterUsers();
  }

  filterUsersByName() {
    this._filterUsers();
  }

  _updateUserRequest(user, data) {
    user.isSaving = true;
    return this.activitySrvc
      .updateActivityUserRequest(this.activityId, data)
      .catch(err => this.ntfsSrvc.error('Unable to update user\'s request', err))
      .finally(() => user.isSaving = false);
  }

  _filterUsers() {
    this.filteredUsers = _.filter(this.allUsers, u => {
      let res = _.startsWith(u.status, this.selectedStatus);
      if (this.searchPhrase) {
        return res && _.chain(u.name).toLower().includes(this.searchPhrase.toLowerCase()).value();
      }
      return res;
    });
  }
}

export const ActivityAdminDashComponent = {
  template: require('./admin-dash.component.pug'),
  controller: ActivityAdminDashCtrl
};
