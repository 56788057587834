export class UserServiceV2 {
  constructor($http) {
    this.ngHttpSrvc = $http;
  }

  getFriends(userId, params) {
    let url = this._getUrl(userId);
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  updateFriendship(userId, params) {
    let url = this._getUrl(userId);
    return this.ngHttpSrvc
      .put(url, JSON.stringify(params))
      .then(res => res.data);
  }

  _getUrl(userId) {
    return `/api/users/${userId}/friends/`;
  }
}
