import { PartnerService } from './service';
import { PartnerInfoComponent } from './info.component';
import { PartnerListComponent } from './list.component';
import { PartnerFormComponent } from './form.component';
import { PartnerDashboardComponent } from './dashboard.component';
import { PartnerStatusComponent } from './status.component';

import './styles.styl';

angular
  .module('czApp.partners', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/partners', {
        template: '<partner-list></partner-list>'
      })
      .when('/partners/info', {
        template: '<partner-info></partner-info>'
      })
      .when('/partners/new', {
        template: '<partner-form></partner-form>'
      })
      .when('/partners/:partnerId/edit', {
        template: '<partner-form></partner-form>'
      })
      .when('/partners/:partnerId/dashboard', {
        template: '<partner-dashboard></partner-dashboard>'
      });
  })
  .service('PartnerService', PartnerService)
  .component('partnerInfo', PartnerInfoComponent)
  .component('partnerList', PartnerListComponent)
  .component('partnerForm', PartnerFormComponent)
  .component('partnerDashboard', PartnerDashboardComponent)
  .component('partnerStatus', PartnerStatusComponent);
