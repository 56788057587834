import {
  getAddressComponentsFromGPlace
} from '../_core/common.helpers';

let _vm;

class ActivityFormCtrl {
  constructor($routeParams, NotificationService, ReturnToService, ActivityService) {
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.activitySrvc = ActivityService;

    this.activityId = $routeParams.id;
    _vm = this;
  }

  $onInit() {
    this._loadActivity();
  }

  _loadActivity() {
    if (!this.activityId) {
      this.activity = {
        status: 'draft'
      };
      return;
    }

    this.isLoading = true;
    return this.activitySrvc
      .getActivityOne(this.activityId)
      .then(activity => this.activity = activity)
      .catch(err => this.ntfsSrvc.error('Unable to load activity', err))
      .finally(() => this.isLoading = false);
  }

  saveActivity() {
    this.isSaving = true;
    let fnName = this.activity.activityId ? 'updateActivity' : 'createActivity';
    return this
      .activitySrvc[fnName](this.activity)
      .then(activity => {
        this.ntfsSrvc.info('Activity saved successfully');
        this.activity.activityId = activity.activityId;
        this.activity.status = activity.status;
        this.returnToSrvc.navigateAndReturnTo(`/activities/${this.activity.activityId}`);
      })
      .catch(err => this.ntfsSrvc.warning('Unable to save activity', err))
      .finally(() => this.isSaving = false);
  }

  imageUploaded(res) {
    this.activity.picUrl = res.s3FilePath;
  }

  locationChanged() {
    let place = this.getPlace();
    _vm.activity.defaultLocation = getAddressComponentsFromGPlace(place);
  }

  returnTo() {
    let url = this.activity.activityId ?
      `/activities/${this.activity.activityId}` :
      '/activities';
    this.returnToSrvc.navigateAndReturnTo(url);
  }
}

export const ActivityFormComponent = {
  template: require('./form.component.pug'),
  controller: ActivityFormCtrl
};
