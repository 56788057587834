import { TransactionService } from './service';
import { TransactionListController } from './list.component';

angular
  .module('czApp.tranx', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/transactions', {
        template: require('./list.component.pug'),
        controller: 'TransactionListController'
      });
  })
  .service('TransactionService', TransactionService)
  .controller('TransactionListController', TransactionListController);
