import { LabService } from './service';
import { LabsDashboardComponent } from './dasboard.component';

import './styles.styl';

angular
  .module('czApp.labs', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/labs', {
        template: '<labs-dashboard></labs-dashboard>'
      });
  })
  .service('LabService', LabService)
  .component('labsDashboard', LabsDashboardComponent);
