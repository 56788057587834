import { EventService } from './service';
import { EventListComponent } from './list.component';
import { EventFormComponent } from './form.component';
import { EventDetailsComponent } from './details.component';
import { EventCardComponent } from './card.component';
import { EventStatusComponent } from './status.component';
import { EventRideRequestFormComponent } from './ride-request.component';
import { EventRideOfferFormComponent } from './ride-offer.component';

import './styles.styl';

angular
  .module('czApp.events', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/events', {
        template: '<event-list></event-list>'
      })
      .when('/events/new', {
        template: '<event-form></event-form>'
      })
      .when('/events/:id', {
        template: '<event-details></event-details>'
      })
      .when('/events/:id/edit', {
        template: '<event-form></event-form>'
      })
      .when('/events/:id/ride-request', {
        template: '<event-ride-request-form></event-ride-request-form>'
      })
      .when('/events/:id/ride-offer', {
        template: '<event-ride-offer-form></event-ride-offer-form>'
      });
  })
  .service('EventService', EventService)
  .component('eventList', EventListComponent)
  .component('eventForm', EventFormComponent)
  .component('eventDetails', EventDetailsComponent)
  .component('eventCard', EventCardComponent)
  .component('eventStatus', EventStatusComponent)
  .component('eventRideRequestForm', EventRideRequestFormComponent)
  .component('eventRideOfferForm', EventRideOfferFormComponent);
