import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

class MessageListCtrl {
  constructor($q, $location, NgTableParams, NotificationService, MessageService) {
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.messageSrvc = MessageService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadMessages.bind(this),
      sorting: { subject: 'asc' }
    });
  }

  $onChanges(changes) {
    if (_.get(changes, 'partnerId.currentValue')) {
      this.qp = { partnerId: changes.partnerId.currentValue };
    }
  }

  _loadMessages(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.messageSrvc.getMessages(qp),
        this.messageSrvc.getMessageCount(qp)
      ])
      .then(([messages, messageCount]) => {
        params.total(messageCount);
        this.messages = messages;
        return messages;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load messages', err))
      .finally(() => this.isLoading = false);
  }

  deleteMessage(message) {
    if (!confirm('Are you sure you want to delete this message?')) {
      return;
    }

    message.isDeleting = true;
    this.messageSrvc
      .deleteMessage(message._id)
      .then(() => {
        this.ntfsSrvc.info('Message deleted successfully');
        _.remove(this.messages, message);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete message', err))
      .finally(() => message.isDeleting = false);
  }

  createMessage() {
    if (this.partnerId) {
      this.ngLocationSrvc.path(`/partners/${this.partnerId}/messages/new`);
    } else {
      this.ngLocationSrvc.path('/messages/new');
    }
  }

  editMessage(message) {
    if (this.partnerId) {
      this.ngLocationSrvc.path(`/partners/${this.partnerId}/messages/${message._id}/edit`);
    } else {
      this.ngLocationSrvc.path(`/messages/${message._id}/edit`);
    }
  }
}

export const MessageListComponent = {
  template: require('./list.component.pug'),
  controller: MessageListCtrl,
  bindings: {
    partnerId: '<',
    isInjected: '<'
  }
};
