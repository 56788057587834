import { MajorDestService } from './service';
import { MajorDestListComponent } from './list.component';
import { MajorDestFormComponent } from './form.component';

import './styles.styl';

angular
  .module('czApp.major-dests', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/major-dests', {
        template: '<major-dest-list></major-dest-list>'
      })
      .when('/major-dests/new', {
        template: '<major-dest-form></major-dest-form>'
      })
      .when('/major-dests/:majorDestId/edit', {
        template: '<major-dest-form></major-dest-form>'
      });
  })
  .service('MajorDestService', MajorDestService)
  .component('majorDestList', MajorDestListComponent)
  .component('majorDestForm', MajorDestFormComponent);
