class UserListController {
  constructor(NotificationService, UserService) {
    this.ntfsSrvc = NotificationService;
    this.userSrvc = UserService;

    this.isLoading = true;
    this.users = [];
    this.currentPage = 1;
    this.docsPerPage = 10;
    this.docCount = 0;
    this.reverse = false;
  }

  $onInit() {
    this.loadUsers();
  }

  loadUsers() {
    this.isLoading = true;
    this.userSrvc
      .query({
        page: this.currentPage,
        pageSize: this.docsPerPage,
        search: this.searchPhrase
      })
      .$promise
      .then(results => {
        this.docCount = results.count;
        this.users = results.users;
      })
      .catch(err => {
        this.ntfsSrvc.error('Unable to load users', err);
        this.docCount = 0;
        this.users = [];
      })
      .finally(() => this.isLoading = false);
  }

  deleteUser(user) {
    this.userSrvc
      .delete({ id: user.userId })
      .$promise
      .then(() => {
        this.ntfsSrvc.info('User is deleted');
        this.loadUsers();
      });
  }

  getStartDocument() {
    return (this.currentPage - 1) * this.docsPerPage + 1;
  }

  getEndDocument() {
    return Math.min(this.docCount, this.currentPage * this.docsPerPage);
  }

  getPageCount() {
    return Math.ceil(this.docCount / this.docsPerPage);
  }

  openPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadUsers();
    }
  }

  openNextPage() {
    if (this.currentPage < this.getPageCount()) {
      this.currentPage++;
      this.loadUsers();
    }
  }

  orderBy(predicate) {
    if (this.predicate === predicate) {
      this.reverse = !this.reverse;
    }
    this.users = _.orderBy(this.users, predicate, this.reverse ? 'desc' : 'asc');
    this.predicate = predicate;
  }
}

export const UserListComponent = {
  template: require('./list.component.pug'),
  controller: UserListController
};
