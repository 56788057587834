class LabsDashboardController {
  constructor(NotificationService, LabService) {
    this.ntfsSrvc = NotificationService;
    this.labSrvc = LabService;
  }

  send7pmDriverNotifications() {
    this.ntfsSrvc.info('Sending 7pm driver notifications...');
    this.labSrvc
      .send7pmDriverNotifications()
      .then(() => this.ntfsSrvc.info('Notifications sent'))
      .catch(err => this.ntfsSrvc.error('Error on sending request', err));
  }

  send3hrsBeforeRideDriverNotifications() {
    this.ntfsSrvc.info('Sending 3 hourse before ride driver notifications...');
    this.labSrvc
      .send3hrsBeforeRideDriverNotifications()
      .then(() => this.ntfsSrvc.info('Notifications sent'))
      .catch(err => this.ntfsSrvc.error('Error on sending request', err));
  }

  sendAvailableSeatsRiderNotifications() {
    this.ntfsSrvc.info('Sending available seats rider notifications...');
    this.labSrvc
      .sendAvailableSeatsRiderNotifications()
      .then(() => this.ntfsSrvc.info('Notifications sent'))
      .catch(err => this.ntfsSrvc.error('Error on sending request', err));
  }

  resendEmailVereficationCode() {
    this.ntfsSrvc.info('Resending email verification code...');
    this.labSrvc
      .resendEmailVereficationCode()
      .then(() => this.ntfsSrvc.info('Email verification code sent'))
      .catch(err => this.ntfsSrvc.error('Error on sending request', err));
  }

  calcNextRunNtfsDate() {
    this.ntfsSrvc.info('Starting calculation...');
    this.labSrvc
      .calcNextRunNtfsDate()
      .then(res => {
        this.ntfsSrvc.info('Calculation finished');
        console.log(res);
      })
      .catch(err => this.ntfsSrvc.error('Error on sending request', err));
  }

  calcAllPlacesRank() {
    this.ntfsSrvc.info('Starting all places rank calculating...');
    this.isAllPlacesRankCalcing = true;
    this.labSrvc
      .calcAllPlacesRank()
      .then(() => this.ntfsSrvc.info('All places rank calculated'))
      .catch(err => this.ntfsSrvc.error('Error on sending request', err))
      .finally(() => this.isAllPlacesRankCalcing = false);
  }

  onCardRegistered(card) {
    this.isCardFormVisible = false;
    if (card) {
      this.ntfsSrvc.info('Card details: ' + JSON.stringify(card, ' ', 2));
    }
  }
}

export const LabsDashboardComponent = {
  template: require('./dashboard.component.pug'),
  controller: LabsDashboardController
};
