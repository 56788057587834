export class AuthTermsCtrl {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  accept() {
    this.isSaving = true;
    this.authSrvc
      .acceptTerms()
      .then(() => this.ngLocationSrvc.path('/'))
      .catch(err => this.ntfsSrvc.warning('Unable to finish request', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthTermsComponent = {
  template: require('./terms.component.pug'),
  controller: AuthTermsCtrl
};
