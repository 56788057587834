import {
  getDatePickerOptions
} from '../_core/common.helpers';
import {
  convertAreaToGmapsFormat,
  convertAreaToMongodbFormat,
  convertAudienceForEdit,
  convertAudienceForSave
} from '../_core/partner.helpers';

class PartnerIncentiveFormCtrl {
  // eslint-disable-next-line max-params
  constructor($routeParams, $location, $timeout, Identity, NotificationService, PartnerIncentiveService) {
    this.ngLocationSrvc = $location;
    this.timeoutSrvc = $timeout;
    this.ntfsSrvc = NotificationService;
    this.incentiveSrvc = PartnerIncentiveService;

    this.partnerId = $routeParams.partnerId;
    this.incentiveId = $routeParams.incentiveId;
    this.isAdmin = Identity.hasRole('admin');
    this.datePickerOptions = getDatePickerOptions();
  }

  $onInit() {
    this._loadPartnerIncentive();
  }

  _loadPartnerIncentive() {
    if (!this.incentiveId) {
      this.incentive = {
        startDate: moment().toDate(),
        endDate: moment().add(1, 'd').toDate(),
        area: {
          isCustom: false
        },
        audience: {
          isCustom: false,
          isValid: true,
          domains: []
        },
        isEditable: true
      };
      return;
    }

    this.isLoading = true;
    return this.incentiveSrvc
      .getPartnerIncentiveOne(this.partnerId, { _id: this.incentiveId })
      .then(incentive => {
        convertAreaToGmapsFormat(incentive.area);
        convertAudienceForEdit(incentive.audience);
        incentive.startDate = new Date(incentive.startDate);
        incentive.endDate = new Date(incentive.endDate);
        this.incentive = incentive;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load incentive', err))
      .finally(() => this.isLoading = false);
  }

  _savePartnerIncentiveAndDoAction({ action, successNtf, errorNtf }) {
    this.isSaving = true;
    let incentiveCopy = _.cloneDeep(this.incentive);
    convertAreaToMongodbFormat(incentiveCopy.area);
    convertAudienceForSave(incentiveCopy.audience);

    let fnName = incentiveCopy._id ? 'updatePartnerIncentive' : 'createPartnerIncentive';
    return this.incentiveSrvc[fnName](this.partnerId, incentiveCopy)
      .then(incentive => {
        this.incentive._id = incentive._id;
        this.incentive.status = incentive.status;
        if (action) {
          return action();
        }
      })
      .then(() => {
        this.ntfsSrvc.info(successNtf);
        this.ngLocationSrvc.path(`/partners/${this.partnerId}/incentives/${this.incentive._id}/edit`);
      })
      .catch(err => this.ntfsSrvc.warning(errorNtf, err))
      .finally(() => this.isSaving = false);
  }

  savePartnerIncentive() {
    return this._savePartnerIncentiveAndDoAction({
      successNtf: 'Incentive saved successfully',
      errorNtf: 'Unable to save incentive'
    });
  }

  changePartnerIncentiveStatus(status) {
    return this._savePartnerIncentiveAndDoAction({
      action: () => {
        this.incentive.status = status;
        return this.incentiveSrvc.updatePartnerIncentiveStatus(this.partnerId, this.incentive);
      },
      successNtf: 'Incentive updated successfully',
      errorNtf: 'Unable to update incentive'
    });
  }

  imageUploaded(res) {
    this.incentive.imageUrl = res.s3FilePath;
  }

  isDisabled() {
    return this.isSaving || !this.incentive.area.isValid || !this.incentive.audience.isValid;
  }
}

export const PartnerIncentiveFormComponent = {
  template: require('./form.component.pug'),
  controller: PartnerIncentiveFormCtrl
};
