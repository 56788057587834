class LeaderboardListCtrl {
  constructor(NotificationService, LeaderboardService) {
    this.ntfsSrvc = NotificationService;
    this.leaderboardSrvc = LeaderboardService;

    this.isLoading = true;
    this.userPoints = [];
  }

  $onInit() {
    this.loadLeaderboards();
  }

  loadLeaderboards() {
    this.isLoading = true;
    this.leaderboardSrvc
      .getUserPoints()
      .then(userPoints => this.userPoints = userPoints)
      .catch(err => this.ntfsSrvc.error('Unable to load Leaderboards', err))
      .finally(() => this.isLoading = false);
  }

  awardUser() {
    this.isUserAwarding = true;
    this.leaderboardSrvc
      .awardUser({
        userId: this.awardedUserId,
        points: this.awardedPoints
      })
      .then(userPoints => this.userPoints.splice(0, 0, userPoints))
      .catch(err => this.ntfsSrvc.error('Unable to award user', err))
      .finally(() => this.isUserAwarding = false);
  }
}

export const LeaderboardListComponent = {
  template: require('./list.component.pug'),
  controller: LeaderboardListCtrl
};
