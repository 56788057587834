class RouteDetailsController {
  // eslint-disable-next-line max-statements, max-params
  constructor($timeout, $location, $routeParams, NotificationService, RouteService) {
    this.ngTimeoutSrvc = $timeout;
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.routeSrvc = RouteService;

    this.isLoading = true;
    this.isMatchedDriversLoading = false;
    this.isMatchedRidersLoading = false;
    this.routeId = $routeParams.routeId;
    this.isRouteVisible = true;
    this.isPointsVisible = false;
    this.isStopsVisible = false;
    this.isPrimeStopsVisible = false;
    this.isRadiusesVisible = true;
    this.isChangingDirection = false;
    this.direction = 'dest';
    this.selectedMatches = [];
  }

  $onInit() {
    this.getRouteDetails();
  }

  getRouteDetails() {
    this.isLoading = true;
    this.routeSrvc
      .get({ routeId: this.routeId }).$promise
      .then(route => {
        route.originLoc = _fixLocation(route.origin);
        route.destLoc = _fixLocation(route.destination);
        route.points = _.map(route.points, point => {
          let isDiscrete = _.isEqual(point.location, route.discreteOrigin) ||
                           _.isEqual(point.location, route.discreteDestination);
          return {
            loc: _fixLocation(point),
            index: point.index,
            originDivertTime: _.get(point, 'divertTimes.origin', 'null'),
            destDivertTime: _.get(point, 'divertTimes.dest', 'null'),
            isDiscrete
          };
        });
        route.stops = _.map(route.stops, stop => {
          return {
            pass: stop.pass,
            loc: _fixLocation(stop),
            index: stop.index,
            originDivertTime: _.get(stop, 'divertTimes.origin', 'null'),
            destDivertTime: _.get(stop, 'divertTimes.dest', 'null'),
            place: stop.place,
            placeRank: stop.placeRank,
            isPrime: stop.isPrime
          };
        })
        let middlePointIndex = Math.round(route.points.length / 2);
        if (route.points.length > middlePointIndex) {
          this.centerLoc = route.points[middlePointIndex].loc;
        } else {
          this.centerLoc = route.originLoc;
        }
        route.searchRadius = route.searchRadius || 3;
        route.warnings = _.orderBy(route.warnings, 'level', 'desc');

        this.primeStopCount = _.filter(route.stops, { isPrime: true }).length;
        this.route = route;
      })
      .then(() => {
        this._filterPoints();
        this._filterStops();
        this._filterPrimeStops();
      })
      .catch(err => this.ntfsSrvc.error('Unable to load route', err))
      .finally(() => this.isLoading = false);
  }

  changeRouteVisibility() {
    this.isRouteVisible = !this.isRouteVisible;
  }

  changePointsVisibility() {
    this.isPointsVisible = !this.isPointsVisible;
    this._filterPoints();
  }

  changeStopsVisibility() {
    this.isStopsVisible = !this.isStopsVisible;
    this._filterStops();
  }

  changePrimeStopsVisibility() {
    this.isPrimeStopsVisible = !this.isPrimeStopsVisible;
    this._filterPrimeStops();
  }

  changeRadiusesVisbility() {
    this.isRadiusesVisible = !this.isRadiusesVisible;
  }

  changeDirection(direction) {
    this.isChangingDirection = true;
    this.direction = direction;
    this._reverseRoute();

    this.ngTimeoutSrvc(() => {
      this.isChangingDirection = false;
    }, 1000);
  }

  getRouteGmapsUrl(route) {
    let origin = route.originLoc[0] + ',' + route.originLoc[1];
    let dest = route.destLoc[0] + ',' + route.destLoc[1];
    return `https://google.com/maps/dir/?api=1&origin=${origin}&destination=${dest}&travelmode=driving`;
  }

  openMatchedDriversTab() {
    if (this.matchedDrivers) {
      return;
    }

    this.isMatchedDriversLoading = true;
    this.routeSrvc
      .getMatchedDrivers({ routeId: this.routeId }).$promise
      .then(matchedDrivers => this.matchedDrivers = this._initMatches(matchedDrivers))
      .catch(err => this.ntfsSrvc.error('Unable to load route drivers', err))
      .finally(() => this.isMatchedDriversLoading = false);
  }

  openMatchedRidersTab() {
    if (this.matchedRiders) {
      return;
    }

    this.isMatchedRidersLoading = true;
    this.routeSrvc
      .getMatchedRiders({ routeId: this.routeId }).$promise
      .then(matchedRiders => this.matchedRiders = this._initMatches(matchedRiders))
      .catch(err => this.ntfsSrvc.error('Unable to load route riders', err))
      .finally(() => this.isMatchedRidersLoading = false);
  }

  toggleMatchVisibility(match) {
    match.isSelected = !match.isSelected;
    if (match.isSelected) {
      this.selectedMatches.push(match);
    } else {
      _.remove(this.selectedMatches, match);
    }
  }

  toggleRouteStatus() {
    this.isProcessing = true;
    let op = this.route.status === 'active' ?
      this.routeSrvc
        .pause({ routeId: this.routeId })
        .$promise
        .then(() => this.route.status = 'suspended') :
      this.routeSrvc
        .resume({ routeId: this.routeId })
        .$promise
        .then(() => this.route.status = 'active');
    op
      .catch(err => this.ntfsSrvc.error('Unable to change route status', err))
      .finally(() => this.isProcessing = false);
  }

  deleteRoute() {
    this.isProcessing = true;
    this.routeSrvc
      .delete({ routeId: this.routeId })
      .$promise
      .then(() => {
        this.ntfsSrvc.info('Route is deleted');
        this.ngLocationSrvc.path('#/routes');
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete route', err))
      .finally(() => this.isProcessing = false);
  }

  _reverseRoute() {
    let originLoc = this.route.originLoc;
    let destLoc = this.route.destLoc;
    this.route.originLoc = destLoc;
    this.route.destLoc = originLoc;
  }

  _filterPoints() {
    this.points = this.isPointsVisible ? this.route.points : [];
  }

  _filterStops() {
    this.stops = this.isStopsVisible ? this.route.stops : [];
  }

  _filterPrimeStops() {
    this.primeStops = this.isPrimeStopsVisible ? _.filter(this.route.stops, { isPrime: true }) : [];
  }

  _initMatches(matches) {
    return _.map(matches, match => {
      match.originLoc = _.reverse(match.origin.location);
      match.destLoc = _.reverse(match.destination.location);
      return match;
    });
  }
}

function _fixLocation(stop) {
  return stop ? stop.location.reverse() : [];
}

export const RouteDetailsComponent = {
  template: require('./details.component.pug'),
  controller: RouteDetailsController
};
