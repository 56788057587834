import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

class DrivingListController {
  constructor($q, $routeParams, Identity, NotificationService, NgTableParams, DrivingService) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.drivingSrvc = DrivingService;
    this.userId = $routeParams.userId;
    this.route = $routeParams.route;
    this.currentUser = Identity.getUser();

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadDrivings.bind(this),
      sorting: { date: 'desc' }
    });
  }

  reloadDrivings() {
    this.tableParams.reload();
  }

  _loadDrivings(params) {
    this.isLoading = true;
    let baseParams = {};
    if (this.myOnly) {
      baseParams.driverUser = this.currentUser.userId
    }
    if (this.userId) {
      baseParams.driverUser = this.userId
    }
    if (this.route) {
      baseParams.route = this.route
    }
    const qp = extendQueryParams(baseParams, params);

    return this.ngQSrvc
      .all([
        this.drivingSrvc.getDrivings(qp),
        this.drivingSrvc.getDrivingCount(qp)
      ])
      .then(([drivings, drivingCount]) => {
        params.total(drivingCount);
        this.drivings = drivings;
        return drivings;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load drivings', err))
      .finally(() => this.isLoading = false);
  }

  deleteDriving(driving) {
    this.drivingSrvc
      .deleteDriving(driving.dcKey)
      .then(() => {
        _.remove(this.drivings, driving);
        this.ntfsSrvc.info('Driving is deleted');
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete driving', err));
  }
}

export const DrivingListComponent = {
  template: require('./list.component.pug'),
  controller: DrivingListController
};
