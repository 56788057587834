export function RouteService($resource) {
  return $resource('/api/admin/routes/:routeId', { routeId: '@routeId', placeId: '@placeId' }, {
    get: {
      method: 'GET'
    },
    getMatchedDrivers: {
      method: 'GET',
      url: '/api/admin/routes/:routeId/matched-drivers',
      isArray: true
    },
    getMatchedRiders: {
      method: 'GET',
      url: '/api/admin/routes/:routeId/matched-riders',
      isArray: true
    },
    query: {
      url:'/api/admin/routes',
      method: 'GET'
    },
    delete: {
      method: 'DELETE'
    },
    pause: {
      method: 'POST',
      url: '/api/admin/routes/:routeId/pause'
    },
    resume: {
      method: 'POST',
      url: '/api/admin/routes/:routeId/resume'
    },
    deleteWorkPlace: {
      method: 'DELETE',
      url: '/api/admin/routes/:routeId/workplaces/:placeId'
    },
    deleteHomePlace: {
      method: 'DELETE',
      url: '/api/admin/routes/:routeId/homeplaces/:placeId'
    },
    regenerate: {
      method: 'PUT',
      url: '/api/admin/routes/:routeId/regenerate'
    }
  });
}
