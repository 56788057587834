import _ from 'lodash';
import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

class EventListCtrl {
  constructor($q, NgTableParams, NotificationService, ReturnToService, EventService) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.eventSrvc = EventService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadEvents.bind(this),
      sorting: {
        'status': 'asc'
      }
    });
    this.returnTo = '/events';
  }

  _loadEvents(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.eventSrvc.getEvents(qp),
        this.eventSrvc.getEventCount(qp)
      ])
      .then(([events, eventCount]) => {
        params.total(eventCount);
        events = _.sortBy(events, [ev => {
          ev.dates = _.sortBy(ev.dates, [d => -new Date(d.fullStartDate)]);
          return -new Date(ev.dates[0].fullStartDate);
        }]);
        this.events = events;
        return events;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load events', err))
      .finally(() => this.isLoading = false);
  }

  createEvent() {
    this.returnToSrvc.navigateAndExtendPath('/events/new', this.returnTo);
  }

  editEvent(event) {
    this.returnToSrvc.navigateAndExtendPath(`/events/${event._id}/edit`, this.returnTo);
  }

  showEvent(event) {
    this.returnToSrvc.navigateAndExtendPath(`/events/${event._id}`, this.returnTo);
  }

  deleteEvent(event) {
    if (!confirm('Are you sure you want to delete this event?')) {
      return;
    }

    event.isDeleting = true;
    this.eventSrvc
      .deleteEvent(event._id)
      .then(() => {
        this.ntfsSrvc.info('Event deleted successfully');
        _.remove(this.events, event);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete event', err))
      .finally(() => event.isDeleting = false);
  }
}

export const EventListComponent = {
  template: require('./list.component.pug'),
  controller: EventListCtrl
};
