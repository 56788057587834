class MapsDirectionsController {
  constructor() {
    this.waypoints = [];
  }

  $onChanges(changes) {
    if (!_.get(changes, 'waypoints.currentValue')) {
      this.waypoints = [];
    }
  }
}

export const MapsDirectionsComponent = {
  template: require('./directions.component.pug'),
  bindings: {
    polyline: '<',
    origin: '<',
    dest: '<',
    waypoints: '<'
  },
  controller: MapsDirectionsController
};
