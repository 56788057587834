class DrivingRiderListController {
  constructor($routeParams, NotificationService, DrivingService, DrivingRiderService) {
    this.ntfsSrvc = NotificationService;
    this.drivingSrvc = DrivingService;
    this.drivingRiderSrvc = DrivingRiderService;
    this.dcKey = $routeParams.dcKey;
    this.drivingRiders = [];
  }

  $onInit() {
    this.loadDrivingRiders();
  }

  loadDrivingRiders() {
    this.drivingRiders = [];
    this.drivingSrvc
      .getDrivingOne({ dcKey: this.dcKey })
      .then(driving => {
        this.ntfsSrvc.info('Driving riders are loaded');
        this.drivingRiders = driving.riders;
      })
      .catch(err => this.ntfsSrvc.error('Error on loading driving riders', err));
  }

  deleteDrivingRider(drivingRider) {
    this.drivingRiderSrvc
      .deleteDrivingRider(drivingRider.dcKey)
      .then(() => {
        _.remove(this.drivingRiders, drivingRider);
        this.ntfsSrvc.info('Driving rider is deleted');
      })
      .catch(err => this.ntfsSrvc.error('Error on deleting driving rider', err));
  }
}

export const DrivingRiderListComponent = {
  template: require('./list.component.pug'),
  controller: DrivingRiderListController
};
