class PlaceFormCtrl {
  constructor($location, $routeParams, NotificationService, PlaceService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.placeSrvc = PlaceService;

    this.isLoading = true;
    this.placeId = $routeParams.id;
  }

  $onInit() {
    this._loadPlace();
  }

  _loadPlace() {
    if (!this.placeId) {
      this.place = {};
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.placeSrvc
      .getPlaceOne(this.placeId)
      .then(place => this.place = place)
      .catch(err => {
        this.ntfsSrvc.error('Unable to place', err);
        this.ngLocationSrvc.path('/places');
      })
      .finally(() => this.isLoading = false);
  }

  savePlace() {
    this.isSaving = true;
    let fn = this.place.placeId ? this.placeSrvc.updatePlace : this.placeSrvc.createPlace;
    fn.call(this.placeSrvc, this.place)
      .then(() => {
        let message = `Place ${this.place.placeId ? 'updated' : 'created'} successfully`;
        this.ntfsSrvc.info(message);
        this.ngLocationSrvc.path('/places');
      })
      .catch(err => this.ntfsSrvc.error('Unable to save place', err))
      .finally(() => this.isSaving = false);
  }
}

export const PlaceFormComponent = {
  template: require('./form.component.pug'),
  controller: PlaceFormCtrl
};
