export class LeaderboardService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.leaderboardsUrl = '/api/admin/leaderboards';
  }

  getUserPoints(params) {
    return this.ngHttpSrvc
      .get(this.leaderboardsUrl, { params })
      .then(res => res.data);
  }

  awardUser(data) {
    let url = this.leaderboardsUrl + '/award';
    return this.ngHttpSrvc
      .post(url, data)
      .then(res => res.data);
  }
}
