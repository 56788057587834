import * as angular from 'angular';
import './vendors';

import './_core/module';
import './_shared/module';
import './auth/module';
import './home/module';
import './places/module';
import './routes/module';
import './users/module';
import './tranx/module';
import './drivings/module';
import './driving-riders/module';
import './partners/module';
import './partner-incentives/module';
import './partner-messages/module';
import './messages/module';
import './incentives/module';
import './leaderboards/module';
import './events/module';
import './ride-requests/module';
import './activities/module';
import './major-dests/module';
import './labs/module';

import './app.styl';
import './map.styl';

angular
  .module('czApp', [
    'ngRoute',
    'ngMap',
    'ngResource',
    'ngStorage',
    'ui.bootstrap',
    'chart.js',
    'ngTable',
    'ngFileUpload',
    'cleave.js',

    'czApp._core',
    'czApp._shared',
    'czApp.auth',
    'czApp.home',
    'czApp.users',
    'czApp.routes',
    'czApp.places',
    'czApp.tranx',
    'czApp.drivings',
    'czApp.driving-riders',
    'czApp.partners',
    'czApp.partner-incentives',
    'czApp.partner-messages',
    'czApp.messages',
    'czApp.incentives',
    'czApp.leaderboards',
    'czApp.events',
    'czApp.ride-requests',
    'czApp.activities',
    'czApp.major-dests',
    'czApp.labs'
  ])
  .config(($locationProvider, $httpProvider, $routeProvider) => {
    $locationProvider.hashPrefix('');
    $httpProvider.interceptors.push('HttpInterceptor');

    $routeProvider.otherwise({
      redirectTo: '/'
    });
  })
  .run(['$rootScope', '$location', 'Identity', ($rootScope, $location, identity) => {
    $rootScope.$on('$routeChangeStart', () => {
      if (!identity.isAuthenticated() &&
        $location.$$path !== '/signup' &&
        $location.$$path !== '/signin' &&
        $location.$$path !== '/mobile-login' &&
        $location.$$path !== '/mobile-login/verification' &&
        $location.$$path !== '/verify-email-token' &&
        $location.$$path !== '/verify-email-code' &&
        $location.$$path !== '/verify-phonenumber-token' &&
        $location.$$path !== '/reset-password' &&
        $location.$$path !== '/verify-password' &&
        !$location.$$path.match(/\/events\/[a-fA-F0-9]{24}$/)
      ) {
        $location.path( '/signin' );
      }
    });
  }]);

angular.bootstrap(document.body, ['czApp']);

