class TzAutocompleteCtrl {
  constructor() {
    this.timezones = moment.tz.names();
  }

  onSelect() {
    this.onChange({ value: this.tz });
  }
}

export const TzAutocompleteComponent = {
  template: require('./tz-autocomple.component.pug'),
  controller: TzAutocompleteCtrl,
  bindings: {
    tz: '<',
    isDisabled: '<',
    onChange: '&'
  }
};
