export function getDatePickerOptions() {
  return {
    formatYear: 'yy',
    showWeeks: false,
    startingDay: 1
  };
}

export function getAddressComponentsFromGPlace(gPlace) {
  let addr = gPlace['address_components'];
  return {
    formattedAddress: _.get(gPlace, 'formatted_address'),
    name: _.get(gPlace, 'name'),
    address: _getAddressComponent(addr, 'street_number') + ' ' + _getAddressComponent(addr, 'route'),
    city: _getAddressComponent(addr, 'locality'),
    state: _getAddressComponent(addr, 'administrative_area_level_1'),
    zip: _getAddressComponent(addr, 'postal_code'),
    latitude: _.get(gPlace, 'geometry.location.lat', () => {})(),
    longitude: _.get(gPlace, 'geometry.location.lng', () => {})()
  };
}

function _getAddressComponent(addrComponents, compType) {
  return _.chain(addrComponents)
    .find(addrComp => _.includes(addrComp.types, compType))
    .get('long_name', '')
    .value();
}
