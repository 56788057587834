/* global google */

let _vm;

import { SF_CENTER } from '../../_core/partner.helpers';

class PartnerMapCtrl {
  constructor($timeout) {
    _vm = this;
    this.timeoutSrvc = $timeout;
    this.sfCenter = SF_CENTER;
  }

  $onChanges(changes) {
    let area = _.get(changes, 'area.currentValue');
    if (area && area.shape && _vm.map) {
      this.fitPolygonBounds();
    }
  }

  mapInitialized(map) {
    _vm.map = map;
    if (_vm.area && _vm.area.shape) {
      this.timeoutSrvc(() => {
        this.fitPolygonBounds();
      }, 1000);
    }
  }

  createPolygon() {
    let mapBounds = _vm.map.getBounds().toJSON();
    let xMargin = (mapBounds.west - mapBounds.east) / 4;
    let yMargin = (mapBounds.north - mapBounds.south) / 4;
    let southWest = [mapBounds.south + yMargin, mapBounds.west - xMargin];
    let northWest = [mapBounds.north - yMargin, mapBounds.west - xMargin];
    let northEast = [mapBounds.north - yMargin, mapBounds.east + xMargin];
    let southEast = [mapBounds.south + yMargin, mapBounds.east + xMargin];

    this.area.shape = null;
    this.timeoutSrvc(() => {
      _.extend(this.area, {
        shape: 'polygon',
        paths: [southWest, northWest, northEast, southEast]
      });
    });
  }

  fitPolygonBounds() {
    let bounds = new google.maps.LatLngBounds();
    _.each(this.area.paths, coords => {
      let latlng = new google.maps.LatLng(coords[0], coords[1]);
      bounds.extend(latlng);
    });
    this.map.fitBounds(bounds);
  }

  locationChanged() {
    let place = this.getPlace();
    if (place && place.geometry) {
      _vm.map.fitBounds(place.geometry.viewport);
    }
  }

  polygonChanged() {
    let vertices = this.getPath();
    let paths = [];
    for (var i = 0; i < vertices.getLength(); i++) {
      let xy = vertices.getAt(i);
      paths.push([xy.lat(), xy.lng()]);
    }

    _.extend(_vm.area, { paths });
  }

  setLocation(location) {
    this.selectedLocation = location;
    this.focusLocation = true;
  }
}

export const PartnerMapComponent = {
  template: require('./map.component.pug'),
  bindings: {
    area: '<',
    isEditable: '<',
    isSaving: '<',
    mailingAddress: '<',
    billingAddress: '<'
  },
  controller: PartnerMapCtrl
};
