import { MessageService } from './service';
import { MessageListComponent } from './list.component';
import { MessageFormComponent } from './form.component';
import { MessageStatusComponent } from './status.component';

import './styles.styl';

angular
  .module('czApp.messages', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/messages', {
        template: '<message-list></message-list>'
      })
      .when('/messages/new', {
        template: '<message-form></message-form>'
      })
      .when('/messages/:messageId/edit', {
        template: '<message-form></message-form>'
      });
  })
  .service('MessageService', MessageService)
  .component('messageList', MessageListComponent)
  .component('messageForm', MessageFormComponent)
  .component('messageStatus', MessageStatusComponent);
