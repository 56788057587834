import * as _ from 'lodash';

export function DollarsToCentsDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      // Don't do anything unless we have a model
      if (ngModel) {
        let dp = parseInt(attrs.dollarsToCents);

        ngModel.$parsers.push((value) => {
          return _.round(value * Math.pow(10, dp));
        });

        ngModel.$formatters.push((value) => {
          return _.round(value) / Math.pow(10, dp);
        });
      }
    }
  };
}
