import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

const MAJOR_DEST_FIELDS = 'name imageUrl owner:firstName:lastName created'

class MajorDestListCtrl {
  // eslint-disable-next-line max-params
  constructor($q, $location, Identity, NgTableParams, NotificationService, MajorDestService) {
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.currUser = Identity.getUser();
    this.isAdmin = Identity.hasRole('admin');
    this.ntfsSrvc = NotificationService;
    this.majorDestSrvc = MajorDestService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadMajorDests.bind(this),
      sorting: { name: 'asc' }
    });
    this.qp = { fields: MAJOR_DEST_FIELDS };
  }

  _loadMajorDests(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.majorDestSrvc.getMajorDests(qp),
        this.majorDestSrvc.getMajorDestCount(qp)
      ])
      .then(([majorDests, majorDestCount]) => {
        _.each(majorDests, majorDest => {
          majorDest.isEditable = this.isAdmin || majorDest.owner === this.currUser.userId;
        });
        params.total(majorDestCount);
        this.majorDests = majorDests;
        return majorDests;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load MajorDests', err))
      .finally(() => this.isLoading = false);
  }

  deleteMajorDest(majorDest) {
    if (!confirm('Are you sure you want to delete this MajorDest?')) {
      return;
    }

    majorDest.isDeleting = true;
    this.majorDestSrvc
      .deleteMajorDest(majorDest._id)
      .then(() => {
        this.ntfsSrvc.info('MajorDest deleted successfully');
        _.remove(this.majorDests, majorDest);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete MajorDest', err))
      .finally(() => majorDest.isDeleting = false);
  }
}

export const MajorDestListComponent = {
  template: require('./list.component.pug'),
  controller: MajorDestListCtrl
};
