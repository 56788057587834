import { RouteService } from './service';
import { RouteServiceV2 } from './service.v2';
import { RouteListComponent } from './list.component';
import { RouteDetailsComponent } from './details.component';
import { RouteAnalysisComponent } from './analysis.component';
import { RouteRegenerateButtonComponent } from './components/regenerate-button.component';
import { RouteAvatarComponent } from './components/avatar.component';
import { RouteDaysTableComponent } from './components/days-table.component';

import './styles.styl';

angular
  .module('czApp.routes', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/routes', {
        template: '<route-list></route-list>'
      })
      .when('/routes/details', {
        template: '<route-details></route-details>'
      })
      .when('/routes/analysis', {
        template: '<route-analysis></route-analysis>'
      });
  })
  .service('RouteService', RouteService)
  .service('RouteServiceV2', RouteServiceV2)
  .component('routeList', RouteListComponent)
  .component('routeDetails', RouteDetailsComponent)
  .component('routeAnalysis', RouteAnalysisComponent)
  .component('routeRegenerateButton', RouteRegenerateButtonComponent)
  .component('routeAvatar', RouteAvatarComponent)
  .component('routeDaysTable', RouteDaysTableComponent);
