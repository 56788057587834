class ActivityBgCheckDashCtrl {
  constructor($routeParams, Identity, NotificationService, ActivityService) {
    this.currentUser = Identity.getUser();
    this.ntfsSrvc = NotificationService;
    this.activitySrvc = ActivityService;

    this.activityId = $routeParams.id;
    this.returnTo = '/activities';
  }

  $onInit() {
    this._loadActivity();
  }

  _loadActivity() {
    this.isLoading = true;
    return this.activitySrvc
      .getActivityWithUsersBgCheck(this.activityId)
      .then(({ activity, users }) => {
        this.activity = activity;
        this.allUsers = users;
        this.filterUsersByStatus('pending');
      })
      .catch(err => this.ntfsSrvc.error('Unable to load activity', err))
      .finally(() => this.isLoading = false);
  }

  updateUserBgCheckStatus(user, newStatus) {
    this
      ._updateUserBgCheck(user, { userId: user.userId, status: newStatus })
      .then(() => {
        user.status = newStatus;
        this._filterUsers();
      });
  }

  updateUserBgCheckNote(user) {
    this._updateUserBgCheck(user, { userId: user.userId, note: user.note });
  }

  deleteUserBgCheck(user) {
    if (!confirm('Are you sure you want to delete the user’s bg-check?')) {
      return;
    }
    user.isSaving = true;
    this.activitySrvc
      .deleteActivityUserBgCheck(this.activityId, { userId: user.userId })
      .then(() => {
        _.remove(this.allUsers, user);
        this._filterUsers();
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete user\'s bg-check', err))
      .finally(() => user.isSaving = false);
  }

  filterUsersByStatus(status) {
    this.selectedStatus = status;
    this._filterUsers();
  }

  filterUsersByName() {
    this._filterUsers();
  }

  _updateUserBgCheck(user, data) {
    user.isSaving = true;
    return this.activitySrvc
      .updateActivityUserBgCheck(this.activityId, data)
      .catch(err => this.ntfsSrvc.error('Unable to update user\'s bg-check', err))
      .finally(() => user.isSaving = false);
  }

  _filterUsers() {
    this.filteredUsers = _.filter(this.allUsers, u => {
      let res = _.startsWith(u.status, this.selectedStatus);
      if (this.searchPhrase) {
        return res && _.chain(u.name).toLower().includes(this.searchPhrase.toLowerCase()).value();
      }
      return res;
    });
  }
}

export const ActivityBgCheckDashComponent = {
  template: require('./bg-check-dash.component.pug'),
  controller: ActivityBgCheckDashCtrl
};
