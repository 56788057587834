import {
  SF_CENTER,
  convertAreaToGmapsFormat,
  getAreaCenter
} from '../_core/partner.helpers';

const PARTNER_FIELDS = 'orgName orgUrl orgType area created status balance lockedBalance owner:firstName:lastName cards';

class PartnerDashboardCtrl {
  constructor($q, $routeParams, $location, NotificationService, PartnerService, TransactionService) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.partnerSrvc = PartnerService;
    this.transService = TransactionService;

    let activeTab = $location.search().tab;
    switch (activeTab) {
      case 'incentives':
        this.activeTabIndex = 4;
        break;
      case 'messages':
        this.activeTabIndex = 5;
        break;
      default:
        this.activeTabIndex = 0;
        break;
    }

    this.selectedPartnerId = $routeParams.partnerId !== '---' ? $routeParams.partnerId : null;
    this.selectedChartPeriod = 'last 30 days';
    this.selectedLeaderboardPeriod = 'this week';
    this.selectedRoutes = [];
    this.areaCenter = SF_CENTER;
    this.chartOptions = {
      legend: {
        display: true
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += _.round(tooltipItem.yLabel, 2);
            return label;
          }
        }
      },
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
  }

  $onInit() {
    this._calcChartsDates();
    this._calcLeaderboardsDates();
    this._loadData();
  }

  onPartnerChange(partner) {
    this.activeTabIndex = 0;
    this.isRoutesLoaded = false;
    this.isChartsLoaded = false;
    this.isLeaderboardsLoaded = false;
    this.isTransactionsLoaded = false;
    this.selectedPartner = partner;
    this.selectedRoutes = [];
    this.isIncentivesTabOpened = false;
    this.isMessagesTabOpened = false;
    this.transactions = [];
    if (this.selectedPartner) {
      this.selectedPartner.selectedCard = this.selectedPartner.cards[0];
    }
    this.loadCharts();
  }

  onChartPeriodChange() {
    this.isChartsLoaded = false;
    this._calcChartsDates();
    this.loadCharts();
  }

  onLeaderboardPeriodChange() {
    this.isLeaderboardsLoaded = false;
    this._calcLeaderboardsDates();
    this.loadLeaderboards();
  }

  toggleRouteVisibility(route) {
    route.isSelected = !route.isSelected;
    if (route.isSelected) {
      this.selectedRoutes.push(route);
    } else {
      _.remove(this.selectedRoutes, route);
    }
  }

  registerCard(regCard) {
    if (!regCard) {
      this.isCardFormVisible = false;
      return;
    }
    this.isAddingFunds = true;
    this.partnerSrvc
      .registerCard(this.selectedPartner._id, regCard)
      .then(card => {
        this.isCardFormVisible = false;
        this.selectedPartner.cards.push(card);
        this.selectedPartner.selectedCard = card;
        this.ntfsSrvc.info('Card has been registered successfully');
      })
      .catch(err => this.ntfsSrvc.error('Unable to register card', err))
      .finally(() => this.isAddingFunds = false);
  }

  useCard(card) {
    this.selectedPartner.selectedCard = card;
  }

  deleteCard(delCard) {
    this.isAddingFunds = true;
    this.partnerSrvc
      .deleteCard(this.selectedPartner._id, delCard.customerId)
      .then(() => {
        _.remove(this.selectedPartner.cards, card => card.customerId === delCard.customerId);
        if (this.selectedPartner.selectedCard === delCard) {
          this.selectedPartner.selectedCard = this.selectedPartner.cards[0];
        }
        this.ntfsSrvc.info('Card has been deleted successfully');
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete card', err))
      .finally(() => this.isAddingFunds = false);
  }

  addFunds() {
    if (this.selectedPartner.cards.length === 0) {
      this.isCardFormVisible = true;
      return;
    }
    if (!this.selectedPartner.selectedCard) {
      this.ntfsSrvc.warning('Select a card');
      return;
    }
    if (!this.fundsAmount) {
      this.ntfsSrvc.warning('Amount is required');
      return;
    }
    this.isAddingFunds = true;
    return this.partnerSrvc
      .charge(
        this.selectedPartner._id,
        this.selectedPartner.selectedCard.customerId,
        this.fundsAmount
      )
      .then(trans => {
        this.selectedPartner.balance += this.fundsAmount;
        this.transactions.splice(0, 0, trans);
      })
      .catch(err => this.ntfsSrvc.error('Unable to add funds', err))
      .finally(() => this.isAddingFunds = false);
  }

  _loadData() {
    this.isLoading = true;
    this.ngQSrvc
      .resolve()
      .then(() => this._loadPartners())
      .then(() => {
        if (!this.selectedPartner || this.selectedPartner.status !== 'approved') {
          return;
        }
        switch (this.activeTabIndex) {
          case 4:
            this.isIncentivesTabOpened = true;
            break;
          case 5:
            this.isMessagesTabOpened = true;
            break;
          default:
            return this.loadCharts();
        }
      })
      .catch(err => this.ntfsSrvc.error('Unable to load Dashboard data', err))
      .finally(() => this.isLoading = false);
  }

  _loadPartners() {
    return this.partnerSrvc
      .getPartners({ fields: PARTNER_FIELDS })
      .then(partners => {
        this.partners = _.chain(partners)
          .sortBy('orgName')
          .each(partner => convertAreaToGmapsFormat(partner.area))
          .value();
        if (this.partners.length > 0) {
          this.selectedPartner = this.selectedPartnerId ?
            _.find(this.partners, { _id: this.selectedPartnerId }) :
            this.partners[0];
          if (this.selectedPartner) {
            this.selectedPartner.selectedCard = this.selectedPartner.cards[0];
          }
        }
      });
  }

  loadCharts() {
    if (this.isChartsLoaded) {
      return;
    }

    this.isChartsLoading = true;
    return this.partnerSrvc
      .getPartnerDashboardCharts({
        _id: this.selectedPartner._id,
        minDate: this.mtChartsMinDate.format(),
        maxDate: this.mtChartsMaxDate.format()
      })
      .then(data => {
        this._initChartLables(data.scale);
        this._initUsersChart(data);
        this._initRidesChart(data);
        this._initVmtChart(data);
        this.isChartsLoaded = true;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load charts', err))
      .finally(() => this.isChartsLoading = false);
  }

  loadRoutes() {
    if (this.isRoutesLoaded) {
      return;
    }
    let area = _.get(this.selectedPartner, 'area');
    this.areaCenter = getAreaCenter(area);

    this.isRoutesLoading = true;
    return this.partnerSrvc
      .getPartnerDashboardRoutes({ _id: this.selectedPartner._id })
      .then(routes => {
        this._initRoutesTable(routes);
        this.isRoutesLoaded = true;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load routes', err))
      .finally(() => this.isRoutesLoading = false);
  }

  loadLeaderboards() {
    if (this.isLeaderboardsLoaded) {
      return;
    }

    this.isLeaderboardsLoading = true;
    return this.partnerSrvc
      .getPartnerDashboardLeaderboards({
        _id: this.selectedPartner._id,
        minDate: this.mtLeaderboardsMinDate.format(),
        maxDate: this.mtMaxLeaderboardsDate.format()
      })
      .then(leaderboards => {
        this._initLeaderboardsTable(leaderboards);
        this.isLeaderboardsLoaded = true;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load leaderboards', err))
      .finally(() => this.isLeaderboardsLoading = false);
  }

  loadTransactions() {
    if (this.isTransactionsLoaded) {
      return;
    }

    this.isTransactionsLoading = true;
    return this.transService
      .getList({ partner: this.selectedPartner._id })
      .$promise
      .then(transactions => this.transactions = transactions)
      .catch(err => this.ntfsSrvc.error('Unable to load transactions', err))
      .finally(() => this.isTransactionsLoading = false);
  }

  _initRoutesTable(routes) {
    _.each(routes, route => {
      route.originLoc = _.reverse(route.discreteOrigin);
      route.destLoc = _.reverse(route.discreteDestination);
    });
    this.routes = routes;
  }

  _initChartLables(scale) {
    let formatString;
    switch (scale) {
      case 'minute':
        formatString = 'hh:mm a';
        break;
      case 'hour':
        formatString = 'hh:mm a';
        break;
      case 'day':
        formatString = 'YYYY-MM-DD';
        break;
      case 'month':
        formatString = 'YYYY-MM';
        break;
      default:
        return;
    }

    this.chartLables = [];
    let date = this.mtChartsMinDate.clone();
    while (date <= this.mtChartsMaxDate) {
      this.chartLables.push(date.format(formatString));
      date.add(1, scale);
    }
  }

  _calcChartsDates() {
    switch (this.selectedChartPeriod) {
      case 'last 30 days':
        this.mtChartsMinDate = moment().startOf('d').subtract(30, 'd');
        this.mtChartsMaxDate = moment();
        break;
      case 'last 6 months':
        this.mtChartsMinDate = moment().startOf('d').subtract(6, 'M');
        this.mtChartsMaxDate = moment();
        break;
      case 'year to date':
        this.mtChartsMinDate = moment().startOf('y');
        this.mtChartsMaxDate = moment();
        break;
      case 'last year':
        this.mtChartsMinDate = moment().startOf('d').subtract(1, 'y');
        this.mtChartsMaxDate = moment();
        break;
    }
  }

  _calcLeaderboardsDates() {
    switch (this.selectedLeaderboardPeriod) {
      case 'this week':
        this.mtLeaderboardsMinDate = moment().startOf('w');
        this.mtMaxLeaderboardsDate = moment();
        break;
      case 'last week':
        this.mtLeaderboardsMinDate = moment().startOf('w').subtract(1, 'w');
        this.mtMaxLeaderboardsDate = moment().endOf('w').subtract(1, 'w');
        break;
      case 'month to date':
        this.mtLeaderboardsMinDate = moment().startOf('M');
        this.mtMaxLeaderboardsDate = moment();
        break;
      case 'last month':
        this.mtLeaderboardsMinDate = moment().startOf('M').subtract(1, 'M');
        this.mtMaxLeaderboardsDate = moment().endOf('M').subtract(1, 'W');
        break;
      case 'this year':
        this.mtLeaderboardsMinDate = moment().startOf('y');
        this.mtMaxLeaderboardsDate = moment();
        break;
    }
  }

  _initUsersChart(data) {
    this.usersChartSeries = ['New users', 'Active users'];
    this.usersChartColors = ['#FF5733', '#FFC300'];
    this.usersChartData = [data.newUsers, data.activeUsers];
    this.totalUserCount = data.totalUserCount;
  }

  _initRidesChart(data) {
    this.ridesChartSeries = ['Rides offered', 'Rides booked'];
    this.ridesChartColors = ['#3498db', '#2ecc71'];
    this.ridesChartData = [data.offeredRides, data.bookedRides];
  }

  _initVmtChart(data) {
    this.vmtChartSeries = ['VMT Reduction'];
    this.vmtChartColors = ['#34495e'];
    this.vmtChartData = [data.vmt];
  }

  _initLeaderboardsTable(leaderboards) {
    this.userPoints = leaderboards;
    this._calcLeaderboardsTable();
  }

  _calcLeaderboardsTable() {
    this.userPoints = _.chain(this.userPoints)
      .each(up => up.pointsTotal = _.sumBy(up.points, 'points'))
      .sortBy(up => -up.pointsTotal)
      .value();
  }
}

export const PartnerDashboardComponent = {
  template: require('./dashboard.component.pug'),
  controller: PartnerDashboardCtrl
};
