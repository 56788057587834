export class Identity {
  constructor($localStorage) {
    this.localStorageSrvc = $localStorage;
  }

  getUser() {
    let userInfo = this.localStorageSrvc.userInfo;
    let user = userInfo ? JSON.parse(userInfo) : null;
    return user;
  }

  getAuthToken() {
    let user = this.getUser();
    return user ? user.authToken : '';
  }

  isAuthenticated() {
    return !!this.getUser();
  }

  hasRole(role) {
    let user = this.getUser();
    return user && user.role === role;
  }
}
