export class ReturnToService {
  constructor($location) {
    this.ngLocationSrvc = $location;
  }

  navigateAndExtendPath(path, returnTo) {
    if (returnTo) {
      this.ngLocationSrvc.search({ returnTo });
    }
    this.ngLocationSrvc.path(path);
  }

  navigateAndReturnTo(defPath = '/') {
    let returnTo = this.ngLocationSrvc.search().returnTo;
    let path = returnTo ? returnTo : defPath;
    this.ngLocationSrvc.search({});
    this.ngLocationSrvc.path(path);
  }
}
