import { PartnerIncentiveService } from './service';
import { PartnerIncentiveListComponent } from './list.component';
import { PartnerIncentiveFormComponent } from './form.component';
import { PartnerIncentiveStatusComponent } from './status.component';

import './styles.styl';

angular
  .module('czApp.partner-incentives', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/partners/:partnerId/incentives/new', {
        template: '<partner-incentive-form></partner-incentive-form>'
      })
      .when('/partners/:partnerId/incentives/:incentiveId/edit', {
        template: '<partner-incentive-form></partner-incentive-form>'
      });
  })
  .service('PartnerIncentiveService', PartnerIncentiveService)
  .component('partnerIncentiveList', PartnerIncentiveListComponent)
  .component('partnerIncentiveForm', PartnerIncentiveFormComponent)
  .component('partnerIncentiveStatus', PartnerIncentiveStatusComponent);
