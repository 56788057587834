export class LabService {
  constructor($http) {
    this.ngHttpSrvc = $http;
  }

  send7pmDriverNotifications() {
    let url = '/api/labs/notifications/driver/7pm';
    return this.ngHttpSrvc
      .post(url)
      .then(res => res.data);
  }

  send3hrsBeforeRideDriverNotifications() {
    let url = '/api/labs/notifications/driver/3hrs-before-ride';
    return this.ngHttpSrvc
      .post(url)
      .then(res => res.data);
  }

  sendAvailableSeatsRiderNotifications() {
    return this.ngHttpSrvc
      .post('/api/labs/notifications/rider/available-seats')
      .then(res => res.data);
  }

  resendEmailVereficationCode() {
    return this.ngHttpSrvc
      .post('/api/users/email/resend')
      .then(res => res.data);
  }

  calcNextRunNtfsDate() {
    return this.ngHttpSrvc
      .post('/api/labs/notifications/calc')
      .then(res => res.data);
  }

  calcAllPlacesRank() {
    return this.ngHttpSrvc
      .post('/api/labs/places/calc')
      .then(res => res.data);
  }
}
