class PlaceListController {
  constructor($routeParams, NotificationService, PlaceService) {
    this.ntfsSrvc = NotificationService;
    this.placeSrvc = PlaceService;

    this.placeId = $routeParams.placeId;

    this.isLoading = true;
    this.places = [];
    this.currentPage = 1;
    this.docsPerPage = 10;
    this.docCount = 0;
    this.reverse = false;
  }

  $onInit() {
    this.loadPlaces();
  }

  loadPlaces() {
    this.isLoading = true;
    this.placeSrvc
      .getPlaces({
        placeId: this.placeId,
        page: this.currentPage,
        pageSize: this.docsPerPage,
        search: this.searchPhrase
      })
      .then(results => {
        this.docCount = results.count;
        this.places = results.places;
      })
      .catch(err => {
        this.ntfsSrvc.error('Unable to load places', err);
        this.docCount = 0;
        this.places = [];
      })
      .finally(() => this.isLoading = false);
  }

  getStartDocument() {
    return (this.currentPage - 1) * this.docsPerPage + 1;
  }

  getEndDocument() {
    return Math.min(this.docCount, this.currentPage * this.docsPerPage);
  }

  getPageCount() {
    return Math.ceil(this.docCount / this.docsPerPage);
  }

  openPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadPlaces();
    }
  }

  openNextPage() {
    if (this.currentPage < this.getPageCount()) {
      this.currentPage++;
      this.loadPlaces();
    }
  }

  orderBy(predicate) {
    if (this.predicate === predicate) {
      this.reverse = !this.reverse;
    }
    this.places = _.orderBy(this.places, predicate, this.reverse ? 'desc' : 'asc');
    this.predicate = predicate;
  }
}

export const PlaceListComponent = {
  template: require('./list.component.pug'),
  controller: PlaceListController
};
