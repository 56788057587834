export class AuthMobileLoginVerificationCtrl {
  constructor($location, $routeParams, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
    this.token = $routeParams.token;
  }

  verify() {
    this.isSaving = true;
    this.authSrvc
      .mobileLoginVerification(this.token, this.code)
      .then(res => {
        this.ngLocationSrvc.search({ token: null });
        if (!res.data.isProvidedDetails) {
          this.ngLocationSrvc.path('/mobile-login/user-details');
        } else if (!res.data.isAcceptedTerms) {
          this.ngLocationSrvc.path('/terms');
        } else {
          this.ngLocationSrvc.path('/');
        }
      })
      .catch(err => this.ntfsSrvc.warning('Unable to verify phone number', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthMobileLoginVerificationComponent = {
  template: require('./verification.component.pug'),
  controller: AuthMobileLoginVerificationCtrl
};
