import {
  getNgTableParams,
  extendQueryParams
} from '../_core/ng-table.helper';

class ActivityListCtrl {
  constructor($q, NgTableParams, NotificationService, ReturnToService, ActivityService) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.activitySrvc = ActivityService;

    this.tableParams = getNgTableParams({
      NgTableParams,
      getData: this._loadActivities.bind(this),
      sorting: { name: 'asc' }
    });
  }

  _loadActivities(params) {
    this.isLoading = true;
    let qp = extendQueryParams(this.qp, params);
    return this.ngQSrvc
      .all([
        this.activitySrvc.getActivities(qp),
        this.activitySrvc.getActivityCount(qp)
      ])
      .then(([activities, activityCount]) => {
        params.total(activityCount);
        this.activities = activities;
        return activities;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load activities', err))
      .finally(() => this.isLoading = false);
  }

  createActivity() {
    this.returnToSrvc.navigateAndExtendPath('/activities/new');
  }

  editActivity(activity) {
    this.returnToSrvc.navigateAndExtendPath(`/activities/${activity.activityId}/edit`);
  }

  showActivity(activity) {
    this.returnToSrvc.navigateAndExtendPath(`/activities/${activity.activityId}`);
  }

  showAdminDash(activity) {
    this.returnToSrvc.navigateAndExtendPath(`/activities/${activity.activityId}/admin-dash`);
  }

  showBgCheckDash(activity) {
    this.returnToSrvc.navigateAndExtendPath(`/activities/${activity.activityId}/bg-check-dash`);
  }

  deleteActivity(activity) {
    if (!confirm('Are you sure you want to delete this activity?')) {
      return;
    }

    activity.isDeleting = true;
    this.activitySrvc
      .deleteActivity(activity.activityId)
      .then(() => {
        this.ntfsSrvc.info('Activity deleted successfully');
        _.remove(this.activities, activity);
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete activity', err))
      .finally(() => activity.isDeleting = false);
  }
}

export const ActivityListComponent = {
  template: require('./list.component.pug'),
  controller: ActivityListCtrl
};
