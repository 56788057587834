import * as toastr from 'toastr';
import { Identity } from './identity';
import { HttpInterceptor } from './http-interceptor';
import { NotificationService } from './notifications.service';
import { ReturnToService } from './return-to.service';

angular
  .module('czApp._core', [])
  .service('HttpInterceptor', HttpInterceptor)
  .service('Identity', Identity)
  .service('NotificationService', NotificationService)
  .service('ReturnToService', ReturnToService)
  .value('toastr', toastr);
