export class AuthChangePhonenumberController {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  changePhonenumber() {
    this.isSaving = true;
    this.authSrvc
      .changePhonenumber(this.rawPhoneNumber, this.countryAlphaCode)
      .then(() => {
        this.ntfsSrvc.info('Sms with instructions sent successfully');
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.warning('Phone number change failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthChangePhonenumberComponent = {
  template: require('./change.component.pug'),
  controller: AuthChangePhonenumberController
};
