export class ClipboardService {
  copy(text) {
    let copyElement = document.createElement('span');
    copyElement.appendChild(document.createTextNode(text));
    copyElement.id = 'tempCopyToClipboard';
    angular.element(document.body.append(copyElement));

    let range = document.createRange();
    range.selectNode(copyElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    copyElement.remove();
  }
}
