class RouteDaysTableCtrl {
  constructor() {
    this.days = [
      { sortName: 'M', fullName: 'Monday' },
      { sortName: 'Tu', fullName: 'Tuesday' },
      { sortName: 'W', fullName: 'Wednesday' },
      { sortName: 'Th', fullName: 'Thursday' },
      { sortName: 'F', fullName: 'Friday' },
      { sortName: 'Sa', fullName: 'Saturday' },
      { sortName: 'Su', fullName: 'Sunday' }
    ];
  }

  isMatchDrivingDay(day) {
    return _.includes(this.route.drivingDays, day.fullName);
  }

  isMatchRidingDay(day) {
    return _.includes(this.route.ridingDays, day.fullName);
  }
}

export const RouteDaysTableComponent = {
  template: require('./days-table.component.pug'),
  bindings: {
    route: '<'
  },
  controller: RouteDaysTableCtrl
};
