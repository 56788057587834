import {
  convertAreaToGmapsFormat,
  convertAreaToMongodbFormat
} from '../_core/partner.helpers';

class MajorDestFormCtrl {
  constructor($routeParams, $location, NotificationService, MajorDestService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.majorDestSrvc = MajorDestService;
    this.majorDestId = $routeParams.majorDestId;
  }

  $onInit() {
    this._loadMajorDest();
  }

  _loadMajorDest() {
    if (!this.majorDestId) {
      this.majorDest = {
        area: {}
      };
      return;
    }

    this.isLoading = true;
    return this.majorDestSrvc
      .getMajorDestOne({ _id: this.majorDestId })
      .then(majorDest => {
        convertAreaToGmapsFormat(majorDest.area);
        this.majorDest = majorDest;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load MajorDest', err))
      .finally(() => this.isLoading = false);
  }

  saveMajorDest() {
    this.isSaving = true;
    let majorDestCopy = _.cloneDeep(this.majorDest);
    convertAreaToMongodbFormat(majorDestCopy.area);

    let fnName = majorDestCopy._id ? 'updateMajorDest' : 'createMajorDest';
    return this.majorDestSrvc[fnName](majorDestCopy)
      .then(majorDest => {
        this.majorDest.status = majorDest.status;
        this.ntfsSrvc.info('MajorDest saved successfully');
        this.ngLocationSrvc.path(`/major-dests/${majorDest._id}/edit`);
      })
      .catch(err => this.ntfsSrvc.error('Unable to save MajorDest', err))
      .finally(() => this.isSaving = false);
  }

  imageUploaded(res) {
    this.majorDest.imageUrl = res.s3FilePath;
  }
}

export const MajorDestFormComponent = {
  template: require('./form.component.pug'),
  controller: MajorDestFormCtrl
};
