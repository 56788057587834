import * as moment from 'moment';

class RouteAnalysisController {
  // eslint-disable-next-line max-statements, max-params
  constructor($q, $timeout, $location, $routeParams, GeoCoder, NotificationService, RouteService, PlaceService, DrivingService) {
    this.ngQSrvc = $q;
    this.ngTimeoutSrvc = $timeout;
    this.ngLocationSrvc = $location;
    this.ngGeoCoder = GeoCoder;
    this.ntfsSrvc = NotificationService;
    this.routeSrvc = RouteService;
    this.placeSrvc = PlaceService;
    this.drivingSrvc = DrivingService;

    this.isLoading = true;
    this.centerLoc = 'San Francisco';
    this.riderRouteId = $routeParams.riderRouteId;
    this.driverRouteId = $routeParams.driverRouteId;
    this.riderWarning = $routeParams.warning;
    this.driverManualStops = [];
    this.isSuggestedStopsVisible = true;
    this.isDriverManualStopsVisible = true;
    this.isRadiusesVisible = true;
    this.direction = 'dest';
  }

  $onInit() {
    this._loadRoutes();
  }

  changeRiderRouteVisibility() {
    this.isRiderRouteVisible = !this.isRiderRouteVisible;
  }

  changeDriverRouteVisibility() {
    this.isDriverRouteVisible = !this.isDriverRouteVisible;
  }

  regenRiderRoute() {
    this._regenRoute(this.riderRoute);
  }

  regenDriverRoute() {
    this._regenRoute(this.driverRoute);
  }

  changeRiderPointsVisibility() {
    this.isRiderPointsVisible = !this.isRiderPointsVisible;
    this._filterPoints();
  }

  changeDriverPointsVisibility() {
    this.isDriverPointsVisible = !this.isDriverPointsVisible;
    this._filterPoints();
  }

  changeRiderStopsVisibility() {
    this.isRiderStopsVisible = !this.isRiderStopsVisible;
    this._filterStops();
  }

  changeDriverStopsVisibility() {
    this.isDriverStopsVisible = !this.isDriverStopsVisible;
    this._filterStops();
  }

  changeSuggestedStopsVisibility() {
    this.isSuggestedStopsVisible = !this.isSuggestedStopsVisible;
    this._filterStops();
  }

  changeDriverManualStopsVisbility() {
    this.isDriverManualStopsVisible = !this.iDriversManualStopsVisible;
  }

  changeSuggestedPathVisibility() {
    this.isSuggestedPathVisible = !this.isSuggestedPathVisible;
    this._updateSuggestedPath();
  }

  changeRadiusesVisbility() {
    this.isRadiusesVisible = !this.isRadiusesVisible;
  }

  changeDirection(direction) {
    this.isChangingDirection = true;
    this.direction = direction;
    this._reverseRoute(this.riderRoute);
    this._reverseRoute(this.driverRoute);
    this._updateSuggestedPath();

    this.ngTimeoutSrvc(() => {
      this.isChangingDirection = false;
    }, 1500);
  }

  addNewStop() {
    this.isAddingNewStop = true;
    this.ngGeoCoder
      .geocode({ address: this.newStopLocation })
      .then(results => {
        if (!results || results.length === 0) {
          this.ntfsSrvc.error('Place not found');
          return;
        }
        let place = results[0];
        let loc = place.geometry.location.toJSON();
        return this.placeSrvc.save({
          sourcePlaceId: place.place_id,
          dataSource: 'Google Places',
          name: place.formatted_address,
          latitude: loc.lat,
          longitude: loc.lng
        });
      })
      .then(place => {
        if (!place) {
          return;
        }
        let stop = {
          loc: _fixLocation(place)
        };
        this.driverManualStops.push(stop);
        this.ntfsSrvc.info('Place created succesfully');
      })
      .catch(err => this.ntfsSrvc.error('Unable to find place', err))
      .finally(() => {
        this.newStopLocation = '';
        this.isAddingNewStop = false;
      });
  }

  getRouteGmapsUrl(route) {
    let origin = route.originLoc[0] + ',' + route.originLoc[1];
    let dest = route.destLoc[0] + ',' + route.destLoc[1];
    return `https://google.com/maps/dir/?api=1&origin=${origin}&destination=${dest}&travelmode=driving`;
  }

  swapRoutes() {
    this.ngLocationSrvc.search({
      riderRouteId: this.driverRouteId,
      driverRouteId: this.riderRouteId
    });
  }

  isStopActual(stop) {
    return !stop.direction || stop.direction === this.direction || stop.direction === 'bi-directional';
  }

  _loadRoutes() {
    this.isLoading = true;
    this.ngQSrvc
      .all([
        this._loadRiderRoute(),
        this._loadDriverRoute()
      ])
      .then(() => this._doAnalyze())
      .then(() => {
        this.ngTimeoutSrvc(() => {
          this.isRiderRouteVisible = true;
        }, 1000);
        this.ngTimeoutSrvc(() => {
          this.isDriverRouteVisible = true;
        }, 2000);
      })
      .catch(err => this.ntfsSrvc.error('Unable to load routes', err))
      .finally(() => this.isLoading = false);
  }

  _loadRiderRoute() {
    return this
      ._loadRoute(this.riderRouteId)
      .then(route => {
        if (route.points.length > 0) {
          let middlePointIndex = Math.round(route.points.length / 2);
          this.centerLoc = route.points[middlePointIndex].loc;
        }
        route.searchRadius = route.searchRadius || 3;
        this.riderRoute = route;
      });
  }

  _loadDriverRoute() {
    return this
      ._loadRoute(this.driverRouteId)
      .then(route => this.driverRoute = route);
  }

  _loadRoute(routeId) {
    return this.routeSrvc
      .get({ routeId }).$promise
      .then(route => {
        route.originLoc = _fixLocation(route.origin);
        route.destLoc = _fixLocation(route.destination);
        _.each(route.points, point => {
          point.loc = _fixLocation(point);
        });
        _.each(route.stops, stop => {
          stop.loc = _fixLocation(stop);
          stop.divertTimes = stop.divertTimes || {};
          stop.divertTimes.dest = _getHumanTime(stop.divertTimes.dest);
          stop.divertTimes.origin = _getHumanTime(stop.divertTimes.origin);
        });
        route.directWaypoints = [];
        route.reverseWaypoints = [];
        return route;
      });
  }

  _doAnalyze() {
    let matchedRoute = _.find(this.riderRoute.matchedDrivers, { route: this.driverRouteId });
    this._markSuggestedPlace(matchedRoute, 'dest.pickupPlace');
    this._markSuggestedPlace(matchedRoute, 'dest.dropoffPlace');
    this._markSuggestedPlace(matchedRoute, 'origin.pickupPlace');
    this._markSuggestedPlace(matchedRoute, 'origin.dropoffPlace');

    _.each(this.riderRoute.points, point => point.isRider = true);
    _.each(this.driverRoute.points, point => point.isDriver = true);
    this.points = _.chain([])
      .concat(this.riderRoute.points)
      .concat(this.driverRoute.points)
      .value();
    this._filterPoints();

    _.each(this.riderRoute.stops, stop => stop.isRider = true);
    _.each(this.driverRoute.stops, stop => stop.isDriver = true);
    this.stops = _.chain([])
      .concat(this.riderRoute.stops)
      .concat(this.driverRoute.stops)
      .value();
    this._filterStops();

    return this._calcRiderPriceAndDistance(matchedRoute);
  }

  _calcRiderPriceAndDistance(matchedRoute) {
    let toDestPlaces = {
      pickupPlace: _.get(matchedRoute, 'suggestedPlaces.dest.pickupPlace'),
      dropoffPlace: _.get(matchedRoute, 'suggestedPlaces.dest.dropoffPlace')
    };
    let toOriginPlaces = {
      pickupPlace: _.get(matchedRoute, 'suggestedPlaces.origin.pickupPlace'),
      dropoffPlace: _.get(matchedRoute, 'suggestedPlaces.origin.dropoffPlace')
    };

    let toDestReq = toDestPlaces.pickupPlace && toDestPlaces.dropoffPlace ?
      this.drivingSrvc.calcDrivingPriceAndDistance({ dcKey: 'null', params: toDestPlaces }) :
      { price: 0, distance: 0 };
    let toOriginReq = toOriginPlaces.pickupPlace && toOriginPlaces.dropoffPlace ?
      this.drivingSrvc.calcDrivingPriceAndDistance({ dcKey: 'null', params: toOriginPlaces }) :
      { price: 0, distance: 0 };

    return this.ngQSrvc
      .all([toDestReq, toOriginReq])
      .then(([toDest, toOrigin]) => this.priceAndDistance = { toDest, toOrigin });
  }

  _markSuggestedPlace(matchedRoute, path) {
    let place = _.get(matchedRoute, `suggestedPlaces.${path}`);
    let spStop = _.find(this.driverRoute.stops, { place });
    if (spStop) {
      spStop.isSP = true;

      let direction = path.includes('dest') ? 'dest' : 'origin';
      if (spStop.direction && spStop.direction !== direction) {
        spStop.direction = 'bi-directional';
      } else {
        spStop.direction = direction;
      }

      let waypoints = direction === 'dest' ? this.driverRoute.directWaypoints : this.driverRoute.reverseWaypoints;
      waypoints.push({
        location: { lat: spStop.loc[0], lng: spStop.loc[1] }
      });
    }
  }

  _filterPoints() {
    this.filteredPoints = _.filter(this.points, point => {
      return (this.isRiderPointsVisible && point.isRider) ||
             (this.isDriverPointsVisible && point.isDriver);
    });
  }

  _filterStops() {
    this.filteredStops = _.filter(this.stops, stop => {
      return (this.isRiderStopsVisible && stop.isRider) ||
             (this.isDriverStopsVisible && stop.isDriver) ||
             (this.isSuggestedStopsVisible && stop.isSP);
    });
  }

  _updateSuggestedPath() {
    if (this.isSuggestedPathVisible) {
      this.driverRoute.waypoints = this.direction === 'dest' ?
        this.driverRoute.directWaypoints :
        this.driverRoute.reverseWaypoints;
    } else {
      this.driverRoute.waypoints = [];
    }
  }

  _reverseRoute(route) {
    let originLoc = route.originLoc;
    let destLoc = route.destLoc;
    route.originLoc = destLoc;
    route.destLoc = originLoc;
  }
}

function _fixLocation(stop) {
  return stop ? stop.location.reverse() : [];
}

function _getHumanTime(secs) {
  if (_.isNil(secs)) {
    return 'null';
  }
  if (secs > 0) {
    return moment.unix(secs).format('mm:ss');
  }
  return secs;
}

export const RouteAnalysisComponent = {
  template: require('./analysis.component.pug'),
  controller: RouteAnalysisController
};
