export class PlaceService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.baseUrl = '/api/places';
  }

  getPlaces(data) {
    let url = '/api/admin/places';
    return this.ngHttpSrvc
      .get(url, { params: data })
      .then(res => res.data);
  }

  getPlaceOne(placeId, params) {
    let url = `${this.baseUrl}/${placeId}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createPlace(place) {
    return this.ngHttpSrvc
      .post(this.baseUrl, JSON.stringify(place))
      .then(res => res.data);
  }

  updatePlace(place) {
    let url = `${this.baseUrl}/${place.placeId}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(place))
      .then(res => res.data);
  }

  deletePlace(placeId) {
    let url = `${this.baseUrl}/${placeId}`;
    return this.ngHttpSrvc.delete(url);
  }
}
