// eslint-disable-next-line max-statements
export function TransactionListController($scope, $filter, TransactionService) {
  $scope.items = [];
  $scope.reverse = false;
  $scope.predicate = 'name';
  $scope.searchPhrase = '';
  $scope.filteredItems = [];
  $scope.Math = window.Math;
  $scope.itemsPerPage = 50;
  $scope.currentPage = 1;
  $scope.count = 0;

  $scope.prevPage = function() {
    if ($scope.currentPage > 1) {
      $scope.currentPage--;
    }
  };

  $scope.prevPageDisabled = function() {
    return $scope.currentPage === 1 ? 'disabled' : '';
  };

  $scope.pageCount = function() {
    return Math.ceil($scope.count / $scope.itemsPerPage);
  };

  $scope.nextPage = function() {
    if ($scope.currentPage < $scope.pageCount()) {
      $scope.currentPage++;
    }
  };

  $scope.nextPageDisabled = function() {
    return $scope.currentPage === $scope.pageCount() ? 'disabled' : '';
  };

  $scope.$watch('searchPhrase', function() {
    $scope.filteredItems = $filter('filter')($scope.items, $scope.searchPhrase);
    $scope.filteredItems = $filter('orderBy')($scope.filteredItems, $scope.predicate);
  });

  $scope.$watch('predicate', function() {
    $scope.filteredItems = $filter('orderBy')(
      $scope.filteredItems,
      $scope.reverse === true ? ('-' + $scope.predicate) : $scope.predicate
    );
  });

  $scope.$watch('reverse', function() {
    $scope.filteredItems = $filter('orderBy')(
      $scope.filteredItems,
      $scope.reverse === true ? ('-' + $scope.predicate) : $scope.predicate
    );
  });

  $scope.$watch('currentPage', function() {
    TransactionService
      .query({
        page: $scope.currentPage,
        pageSize: $scope.itemsPerPage
      })
      .$promise
      .then(data => {
        $scope.items = data.transactions;
        $scope.count = data.count;
        $scope.filteredItems = $filter('filter')($scope.items, $scope.searchPhrase);
      });
  });
}
