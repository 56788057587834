import { PlaceService } from './service';
import { PlaceListComponent } from './list.component';
import { PlaceFormComponent } from './form.component';

import './styles.styl';

angular
  .module('czApp.places', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/places', {
        template: '<place-list></place-list>'
      })
      .when('/places/new', {
        template: '<place-form></place-form>'
      })
      .when('/places/:id/edit', {
        template: '<place-form></place-form>'
      });
  })
  .service('PlaceService', PlaceService)
  .component('placeList', PlaceListComponent)
  .component('placeForm', PlaceFormComponent);
