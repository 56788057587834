export class AuthVerifyPhonenumberTokenController {
  constructor($routeParams, AuthService) {
    this.authSrvc = AuthService;
    this.token = $routeParams.token;
  }

  $onInit() {
    this.verifyPhonenumber();
  }

  verifyPhonenumber() {
    if (!this.token) {
      this.errorAlert = 'Token is required!';
      return;
    }

    this.isSaving = true;
    this.authSrvc
      .verifyPhonenumberToken(this.token)
      .then(data => this.successAlert = data.message)
      .catch(err => {
        this.errorAlert = _.get(err, 'message') || _.get(err, 'data.message') || 'Something went wrong';
      })
      .finally(() => this.isSaving = false);
  }
}

export const AuthVerifyPhonenumberTokenComponent = {
  template: require('./verify-token.component.pug'),
  controller: AuthVerifyPhonenumberTokenController
};
