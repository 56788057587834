class CallFormCtrl {
  constructor($http, $timeout, NotificationService) {
    this.ngHttpSrvc = $http;
    this.ngTimeoutSrvc = $timeout;
    this.ntfsSrvc = NotificationService;
  }

  $onInit() {
    this._init();
  }

  $onChanges(changes) {
    let initialPhoneNumber = _.get(changes, 'initialPhoneNumber.currentValue');
    if (initialPhoneNumber) {
      this.phoneNumber = initialPhoneNumber;
    }
  }

  call() {
    if (this.device) {
      let outgoingConnection = this.device.connect({ To: this.phoneNumber });
      outgoingConnection.on('ringing', () => {
        this.ntfsSrvc.info('Ringing...');
        this.ngTimeoutSrvc(() => this.isCalling = true);
      });
    }
  }

  hangup() {
    this.ntfsSrvc.info('Hanging up...');
    if (this.device) {
      this.device.disconnectAll();
    }
  }

  _init() {
    return this.ngHttpSrvc
      .get('https://silver-gorilla-9366.twil.io/capability-token')
      .then(res => {
        this.device = new Twilio.Device(res.data.token, {
          codecPreferences: ['opus', 'pcmu'],
          fakeLocalDTMF: true,
          enableRingingState: true
        });

        this.device.on('ready', () => {
          this.ngTimeoutSrvc(() => this.isVisibleCallControls = true);
        });

        this.device.on('error', err => {
          this.ntfsSrvc.warning('Twilio.Device Error: ' + err.message);
          this.ngTimeoutSrvc(() => this.isCalling = false);
        });

        this.device.on('connect', () => {
          this.ntfsSrvc.info('Successfully established call!');
        });

        this.device.on('disconnect', () => {
          this.ntfsSrvc.info('Call ended.');
          this.ngTimeoutSrvc(() => this.isCalling = false);
        });

        this.device.on('incoming', conn => {
          this.ntfsSrvc.info('Incoming connection from ' + conn.parameters.From);
          let archEnemyPhoneNumber = '+12093373517';
          if (conn.parameters.From === archEnemyPhoneNumber) {
            conn.reject();
            this.ntfsSrvc.warning('It\'s your nemesis. Rejected call.');
          } else {
            conn.accept();
          }
        });
      })
      .catch(() => this.ntfsSrvc.warning('Could not initialize Twilio service!'));
  }
}

export const CallFormComponent = {
  template: require('./call-form.component.pug'),
  bindings: {
    initialPhoneNumber: '<'
  },
  controller: CallFormCtrl
};
