import {
  getDatePickerOptions
} from '../_core/common.helpers';
import {
  convertAreaToGmapsFormat,
  convertAreaToMongodbFormat,
  convertAudienceForEdit,
  convertAudienceForSave
} from '../_core/partner.helpers';

class MessageFormCtrl {
  constructor($q, $routeParams, $location, NotificationService, MessageService) {
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.messageSrvc = MessageService;

    this.messageId = $routeParams.messageId;
    this.partnerId = $routeParams.partnerId;
    this.defaultDeliveries = [
      { name: 'Email', value: 'email' },
      { name: 'Sms', value: 'sms' },
      { name: 'Notification', value: 'notification' },
      { name: 'News Feed', value: 'news-feed' }
    ];
    this.datePickerOptions = getDatePickerOptions();
  }

  $onInit() {
    this._loadMessage();
  }

  _loadMessage() {
    if (!this.messageId) {
      this.message = {
        status: 'draft',
        timezone: 'America/Los_Angeles',
        startDate: moment().startOf('d').toDate(),
        startTime: moment().startOf('d').toDate(),
        endDate: moment().startOf('d').add(1, 'd').toDate(),
        sendToAll: true,
        partner: this.partnerId,
        area: {
          isCustom: !this.partnerId,
          isValid: true
        },
        audience: {
          isCustom: false,
          isValid: true,
          domains: []
        },
        deliveries: this.defaultDeliveries
      };
      return;
    }

    this.isLoading = true;
    return this.messageSrvc
      .getMessageOne({ _id: this.messageId })
      .then(message => {
        convertAreaToGmapsFormat(message.area);
        convertAudienceForEdit(message.audience);
        message.deliveries = _.map(this.defaultDeliveries, dd => ({
          name: dd.name,
          value: dd.value,
          enabled: _.includes(message.deliveries, dd.value)
        }));
        message.startDate = moment(message.startDate, 'YYYY-MM-DD').toDate();
        message.startTime = moment(message.startTime, 'HH:mm').toDate();
        message.endDate = moment(message.endDate, 'YYYY-MM-DD').toDate();
        this.message = message;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load message', err))
      .finally(() => this.isLoading = false);
  }

  _saveMessageAndDoAction({ action, successNtf, errorNtf }) {
    let messageCopy = _.cloneDeep(this.message);
    convertAreaToMongodbFormat(messageCopy.area);
    convertAudienceForSave(messageCopy.audience);
    messageCopy.deliveries = _.chain(this.message.deliveries)
      .filter({ enabled: true })
      .map('value')
      .value();
    messageCopy.startDate = moment(messageCopy.startDate).format('YYYY-MM-DD');
    messageCopy.startTime = moment(messageCopy.startTime).format('HH:mm');
    messageCopy.endDate = moment(messageCopy.endDate).format('YYYY-MM-DD');

    this.isSaving = true;
    let fnName = messageCopy._id ? 'updateMessage' : 'createMessage';
    return this
      .messageSrvc[fnName](messageCopy)
      .then(message => {
        this.message._id = message._id;
        this.message.status = message.status;
        if (action) {
          return action();
        }
      })
      .then(() => {
        this.ntfsSrvc.info(successNtf);
        if (this.partnerId) {
          this.ngLocationSrvc.path(`/partners/${this.partnerId}/messages/${this.message._id}/edit`);
        } else {
          this.ngLocationSrvc.path(`/messages/${this.message._id}/edit`);
        }
      })
      .catch(err => this.ntfsSrvc.warning(errorNtf, err))
      .finally(() => this.isSaving = false);
  }

  saveMessage() {
    return this._saveMessageAndDoAction({
      successNtf: 'Message saved successfully',
      errorNtf: 'Unable to save message'
    });
  }

  sendTestMessage() {
    return this._saveMessageAndDoAction({
      action: () => this.messageSrvc.sendTestMessage(this.message),
      successNtf: 'Test message sent successfully',
      errorNtf: 'Unable to send message'
    });
  }

  changeMessageStatus(status) {
    return this._saveMessageAndDoAction({
      action: () => {
        this.message.status = status;
        return this.messageSrvc.updateMessageStatus(this.message);
      },
      successNtf: 'Message updated successfully',
      errorNtf: 'Unable to update message'
    });
  }

  changeTz(tz) {
    this.message.timezone = tz;
  }

  isDisabled() {
    return this.isSaving || !this.message.area.isValid || !this.message.audience.isValid || this.isEmptyDeliveries();
  }

  isEmptyDeliveries() {
    return _.filter(this.message.deliveries, { enabled: true }).length === 0;
  }

  return() {
    if (this.partnerId) {
      this.ngLocationSrvc.search({ tab: 'messages' });
      this.ngLocationSrvc.path(`/partners/${this.partnerId}/dashboard`);
    } else {
      this.ngLocationSrvc.path('/messages');
    }
  }

  imageUploaded(res) {
    this.message.imageUrl = res.s3FilePath;
  }
}

export const MessageFormComponent = {
  template: require('./form.component.pug'),
  controller: MessageFormCtrl
};
