angular
  .module('czApp.partner-messages', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/partners/:partnerId/messages/new', {
        template: '<message-form></message-form>'
      })
      .when('/partners/:partnerId/messages/:messageId/edit', {
        template: '<message-form></message-form>'
      });
  });
