import {
  getDatePickerOptions,
  getAddressComponentsFromGPlace
} from '../_core/common.helpers';

let _vm;

class EventFormCtrl {
  constructor($routeParams, NotificationService, ReturnToService, EventService) {
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.eventSrvc = EventService;

    this.eventId = $routeParams.id;
    this.datePickerOptions = getDatePickerOptions();
    _vm = this;
  }

  $onInit() {
    this._loadEvent();
  }

  _loadEvent() {
    if (!this.eventId) {
      this.event = {
        status: 'draft',
        searchable: true,
        newsFeed: false,
        dates: [{}],
        venue: {}
      };
      this.editingDate = this.event.dates[0];
      return;
    }

    this.isLoading = true;
    return this.eventSrvc
      .getEventOne(this.eventId)
      .then(event => {
        _.each(event.dates, date => {
          date.date = moment(date.date, 'YYYY-MM-DD').toDate();
          date.startTime = moment(date.startTime, 'HH:mm').toDate();
          date.endTime = moment(date.endTime, 'HH:mm').toDate();
        });
        this.event = event;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load event', err))
      .finally(() => this.isLoading = false);
  }

  saveEvent() {
    let eventCopy = _.cloneDeep(this.event);
    _.each(eventCopy.dates, date => {
      date.date = moment(date.date).format('YYYY-MM-DD');
      date.startTime = moment(date.startTime).format('HH:mm');
      date.endTime = moment(date.endTime).format('HH:mm');
    });

    this.isSaving = true;
    let fnName = eventCopy._id ? 'updateEvent' : 'createEvent';
    return this
      .eventSrvc[fnName](eventCopy)
      .then(event => {
        this.ntfsSrvc.info('Event saved successfully');
        this.event._id = event._id;
        this.event.status = event.status;
        this.returnToSrvc.navigateAndReturnTo(`/events/${this.event._id}`);
      })
      .catch(err => this.ntfsSrvc.warning('Unable to save event', err))
      .finally(() => this.isSaving = false);
  }

  addDate() {
    let date = {
      date: moment().toDate(),
      startTime: moment().hours(18).minutes(0).toDate(),
      endTime: moment().hours(19).minutes(0).toDate()
    };
    this.event.dates.push(date);
    this.editingDate = date;
  }

  editDate(date) {
    if (this.editingDate === date) {
      this.editingDate = null;
    } else {
      this.editingDate = date;
    }
  }

  deleteDate(date) {
    if (date === this.editingDate) {
      this.editingDate = null;
    }
    _.remove(this.event.dates, date);
  }

  imageUploaded(res) {
    this.event.bannerImageUrl = res.s3FilePath;
  }

  venueChanged() {
    let place = this.getPlace();
    _vm.event.venue = getAddressComponentsFromGPlace(place);
  }

  returnTo() {
    this.returnToSrvc.navigateAndReturnTo('/events');
  }
}

export const EventFormComponent = {
  template: require('./form.component.pug'),
  controller: EventFormCtrl
};
