import { DrivingService } from './service';
import { DrivingListComponent } from './list.component';
import { DrivingFormComponent } from './form.component';

import './styles.styl';

angular
  .module('czApp.drivings', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/drivings', {
        template: '<driving-list></driving-list>'
      })
      .when('/drivings/new', {
        template: '<driving-form></driving-form>'
      })
      .when('/drivings/:dcKey/edit', {
        template: '<driving-form></driving-form>'
      });
  })
  .service('DrivingService', DrivingService)
  .component('drivingList', DrivingListComponent)
  .component('drivingForm', DrivingFormComponent);
