export function TransactionService($resource) {
  return $resource('/api/admin/transactions/:id', {}, {
    get: {
      method: 'GET'
    },
    query: {
      url: '/api/admin/transactions',
      method: 'GET'
    },
    getList: {
      url: '/api/billing/transactions',
      method: 'GET',
      isArray: true
    }
  });
}
