export class RouteServiceV2 {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.baseUrl = '/api/routes';
  }

  createRoute(route) {
    return this.ngHttpSrvc
      .post(this.baseUrl, JSON.stringify(route))
      .then(res => res.data);
  }
}
