class ActivityDetailsCtrl {
  constructor($routeParams, $location, Identity, NotificationService, ReturnToService, ClipboardService, ActivityService) {
    this.locationSrvc = $location;
    this.currentUser = Identity.getUser();
    this.returnToSrvc = ReturnToService;
    this.ntfsSrvc = NotificationService;
    this.clipboardSrvc = ClipboardService;
    this.activitySrvc = ActivityService;

    this.activityId = $routeParams.id;
    this.returnTo = `/activities/${this.activityId}`;
  }

  $onInit() {
    this._loadActivity();
  }

  _loadActivity() {
    let server = this.locationSrvc.protocol() + '://' + this.locationSrvc.host();
    if (this.locationSrvc.port()) {
      server += ':' + this.locationSrvc.port();
    }
    this.isLoading = true;
    return this.activitySrvc
      .getActivityOne(this.activityId)
      .then(activity => {
        activity.defaultLocation.location = _.reverse(activity.defaultLocation.location);
        activity.inviteLink = `${server}/invite/${activity.inviteToken}`;
        this.selectedProgram = activity.programs[0];
        this.activity = activity;
        this.allowEdit = _.get(this.currentUser, 'userId') === activity.creator;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load activity', err))
      .finally(() => this.isLoading = false);
  }

  editActivity() {
    this.returnToSrvc.navigateAndExtendPath(`/activities/${this.activityId}/edit`, this.returnTo);
  }

  copyInviteLink() {
    this.clipboardSrvc.copy(this.activity.inviteLink);
    alert('Invite link copied to clipboard');
  }

  addProgram() {
    this.newProgram = {};
  }

  renameProgram() {
    this.isSaving = true;
    this.activitySrvc
      .updateActivityProgram(
        this.activityId,
        this.selectedProgram._id,
        {
          name: this.selectedProgram.name,
          moreInfoUrl: this.selectedProgram.moreInfoUrl
        }
      )
      .then(() => this.editProgramNameMode = false)
      .catch(err => this.ntfsSrvc.error('Unable to save program', err))
      .finally(() => this.isSaving = false);
  }

  deleteProgram() {
    if (!confirm('Are you sure you want to delete this program?')) {
      return;
    }
    this.isSaving = true;
    this.activitySrvc
      .deleteActivityProgram(this.activityId, this.selectedProgram._id)
      .then(() => {
        _.remove(this.activity.programs, { _id: this.selectedProgram._id });
        this.selectedProgram = this.activity.programs[0];
      })
      .catch(err => this.ntfsSrvc.error('Unable to delete program', err))
      .finally(() => this.isSaving = false);
  }

  fileUploaded(res) {
    this.newProgram = null;
    this.activity.programs.push(res);
    this.selectedProgram = res;
  }
}

export const ActivityDetailsComponent = {
  template: require('./details.component.pug'),
  controller: ActivityDetailsCtrl
};
