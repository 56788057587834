import {
  getDatePickerOptions,
  getAddressComponentsFromGPlace
} from '../_core/common.helpers';
import {
  getEventActualDate,
  getRequestForSave
} from '../_core/events.helpers';

let _vm;

class EventRideRequestFormCtrl {
  constructor(
    $routeParams, $q, NotificationService, ReturnToService,
    EventService, RouteServiceV2, RideRequestService
  ) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.eventSrvc = EventService;
    this.routeSrvc = RouteServiceV2;
    this.rideRequestSrvc = RideRequestService;

    _vm = this;
    this.datePickerOptions = getDatePickerOptions();
    this.eventId = $routeParams.id;
    this.numbers10 = _.times(10, index => index + 1);
  }

  $onInit() {
    this.request = {
      direction: 'roundtrip',
      seats: 1,
      returnSeats: 1,
      requirements: {}
    };
    this._loadEvent();
  }

  _loadEvent() {
    this.isLoading = true;
    return this.eventSrvc
      .getEventOne(this.eventId)
      .then(event => {
        let eventActualDate = getEventActualDate(event.dates);
        this.request.date = moment(eventActualDate.fullStartDate).toDate();
        this.request.time = moment(eventActualDate.fullStartDate).toDate();
        this.request.returnDate = moment(eventActualDate.fullEndDate).toDate();
        this.request.returnTime = moment(eventActualDate.fullEndDate).toDate();
        this.event = event;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load event', err))
      .finally(() => this.isLoading = false);
  }

  setDirection(direction) {
    this.request.direction = direction;
  }

  fromChanged() {
    let place = this.getPlace();
    _vm.request.from = getAddressComponentsFromGPlace(place);
  }

  toChanged() {
    let place = this.getPlace();
    _vm.request.to = getAddressComponentsFromGPlace(place);
  }

  createRequest() {
    let requestForSave = getRequestForSave(this.request, this.event);

    this.isSaving = true;
    this.ngQSrvc
      .resolve()
      .then(() => this._createRoute(requestForSave, requestForSave.from, requestForSave.to))
      .then(route => this._createRideRequest(requestForSave, route))
      .then(() => {
        this.ntfsSrvc.info('Request created successfully');
        this.returnToSrvc.navigateAndReturnTo(`/events/${this.eventId}`);
      })
      .catch(err => this.ntfsSrvc.warning('Unable to create request', err))
      .finally(() => this.isSaving = false);
  }

  _createRoute(request, originLoc, destLoc) {
    let route = {
      type: 'onetime',
      origin: originLoc,
      destination: destLoc,
      time: request.time,
      returnTime: request.returnTime,
      event: this.eventId
    };
    return this.routeSrvc.createRoute(route);
  }

  _createRideRequest(request, route) {
    let direction;
    switch (request.direction) {
      case 'there':
        direction = 'dest';
        break;
      case 'back':
        direction = 'origin';
        break;
      case 'roundtrip':
        direction = request.direction;
        break;
    }
    let rideRequest = _.pick(request, [
      'date', 'time', 'returnDate', 'returnTime',
      'seats', 'returnSeats', 'note', 'requirements'
    ]);
    rideRequest.direction = direction;

    return this.rideRequestSrvc.updateRideRequest(route.routeId, rideRequest);
  }

  return() {
    this.returnToSrvc.navigateAndReturnTo();
  }
}

export const EventRideRequestFormComponent = {
  template: require('./ride-request.component.pug'),
  controller: EventRideRequestFormCtrl
};
