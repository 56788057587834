export class RideRequestService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.baseUrl = '/api/ride-requests';
  }

  getRideRequestOne(routeId, params) {
    let url = `${this.baseUrl}/${routeId}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  updateRideRequest(routeId, rideRequest) {
    let url = `${this.baseUrl}/${routeId}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(rideRequest))
      .then(res => res.data);
  }

  deleteRideRequest(routeId) {
    let url = `${this.baseUrl}/${routeId}`;
    return this.ngHttpSrvc.delete(url);
  }
}
