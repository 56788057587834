export class AuthSignupCtrl {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  isPasswordsMatch() {
    return this.password === this.passwordConfirm;
  }

  signup() {
    this.isSaving = true;
    this.authSrvc
      .signup(this.email, this.password)
      .then(() => {
        this.ntfsSrvc.info('Welcome to Carzac. Please sign in using your credentials.');
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.warning('Sign up failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthSignupComponent = {
  template: require('./signup.component.pug'),
  controller: AuthSignupCtrl
};
