import {
  getAddressComponentsFromGPlace
} from '../_core/common.helpers';

let _vm;

class DrivingRiderBookSeatCtrl {
  constructor(
    $q, $location, $routeParams, GeoCoder, NotificationService,
    DrivingService, DrivingRiderService, PlaceService
  ) {
    _vm = this;
    this.ngQSrvc = $q;
    this.ngLocationSrvc = $location;
    this.ngGeoCoder = GeoCoder;
    this.ntfsSrvc = NotificationService;
    this.drivingSrvc = DrivingService;
    this.drivingRiderSrvc = DrivingRiderService;
    this.placeSrvc = PlaceService;
    this.dcKey = $routeParams.dcKey;
    this.riderRouteId = $routeParams.riderRoute;
  }

  $onInit() {
    this._loadData();
  }

  _loadData() {
    this.isLoading = true;
    this.drivingSrvc
      .getDrivingOne({
        dcKey: this.dcKey,
        params: {
          riderRoute: this.riderRouteId,
          details: true
        }
      })
      .then(driving => {
        driving.timeFormatted = moment(driving.time, 'HH:mm').format('h:mm A');
        driving.pickupTimeFormatted = moment(driving.pickupTime, 'HH:mm').format('h:mm A');
        driving.driverRoute.origin.locationGM = _fixLocation(driving.driverRoute.origin);
        driving.driverRoute.destination.locationGM = _fixLocation(driving.driverRoute.destination);
        if (driving.driverRoute.points.length > 0) {
          let middlePointIndex = Math.round(driving.driverRoute.points.length / 2);
          driving.driverRoute.centerLocationGM = _fixLocation(driving.driverRoute.points[middlePointIndex]);
        }
        _fixSuggestedPlace(driving.suggestedPlaces.pickupPlace);
        _fixSuggestedPlace(driving.suggestedPlaces.dropoffPlace);

        this.driverUser = driving.driverUser;
        this.driverRoute = driving.driverRoute;
        this.suggestedPlaces = driving.suggestedPlaces;
        this.driving = driving;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load data', err))
      .finally(() => this.isLoading = false);
  }

  _refreshRiderDetails() {
    // TODO: impossible to use, because placeId can be undefined if place is changed
    /*
    this.isSaving = true;
    this.drivingSrvc
      .calcDrivingPriceAndDistance({
        dcKey: this.dcKey,
        params: {
          pickupPlace: this.suggestedPlaces.pickupPlace.placeId,
          dropoffPlace: this.suggestedPlaces.dropoffPlace.placeId,
        }
      })
      .then(driving => {
        driving.pickupTimeFormatted = moment(driving.pickupTime, 'HH:mm').format('h:mm A');
        this.driving = driving;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load data', err))
      .finally(() => this.isSaving = false);
    */
  }

  chat() {
    alert('Not implemented');
  }

  call() {
    alert('Not implemented');
  }

  // TODO: remove?
  editPickupPlace() {
    alert('Not implemented');
  }

  // TODO: remove?
  editDropoffPlace() {
    alert('Not implemented');
  }

  dragPickupPlace(ev) {
    _updateSuggestedPlage(_vm.ngGeoCoder, _vm.suggestedPlaces.pickupPlace, ev.latLng)
      .then(() => _vm._refreshRiderDetails());
  }

  dragDropoffPlace(ev) {
    _updateSuggestedPlage(_vm.ngGeoCoder, _vm.suggestedPlaces.dropoffPlace, ev.latLng)
      .then(() => _vm._refreshRiderDetails());
  }

  bookSeat() {
    this.isSaving = true;
    this.ngQSrvc
      .resolve()
      .then(() => this._createPlacesIfNeeded())
      .then(() => {
        let drivingRiderData = {
          route: this.riderRouteId,
          pickupTime: this.driving.pickupTime,
          pickupPlace: this.suggestedPlaces.pickupPlace.placeId,
          dropoffPlace: this.suggestedPlaces.dropoffPlace.placeId
        };
        return this.drivingRiderSrvc.createDrivingRider(this.dcKey, drivingRiderData);
      })
      .then(() => {
        this.ntfsSrvc.info('DrivingRider created succesfully');
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.error('Unable to load data', err))
      .finally(() => this.isSaving = false);
  }

  _createPlacesIfNeeded() {
    let ops = [];
    if (!this.suggestedPlaces.pickupPlace.placeId) {
      ops.push(this.placeSrvc.createPlace(this.suggestedPlaces.pickupPlace));
    } else {
      ops.push(null);
    }
    if (!this.suggestedPlaces.dropoffPlace.placeId) {
      ops.push(this.placeSrvc.createPlace(this.suggestedPlaces.dropoffPlace));
    } else {
      ops.push(null);
    }

    return this.ngQSrvc
      .all(ops)
      .then(results => {
        if (results[0]) {
          this.suggestedPlaces.pickupPlace.placeId = results[0].placeId;
        }
        if (results[1]) {
          this.suggestedPlaces.dropoffPlace.placeId = results[1].placeId;
        }
      });
  }
}

function _fixLocation(loc) {
  return loc ? loc.location.reverse() : [];
}

function _fixSuggestedPlace(sugPlace) {
  sugPlace.locationGM = _fixLocation(sugPlace);
  sugPlace.formattedAddress = _.join([sugPlace.address, sugPlace.city, sugPlace.zip, 'USA'], ', ');
}

function _updateSuggestedPlage(GeoCoder, sugPlace, latLng) {
  return GeoCoder
    .geocode({ latLng: latLng })
    .then(results => {
      if (!results || results.length === 0) {
        _vm.ntfsSrvc.error('Place not found');
        return;
      }
      let place = results[0];
      _.extend(sugPlace, getAddressComponentsFromGPlace(place));
      sugPlace.locationGM = [latLng.lat(), latLng.lng()];
      sugPlace.sourcePlaceId = place.place_id;
      sugPlace.dataSource = 'Google Places';
      sugPlace.name = sugPlace.name || 'Unnamed';
      sugPlace.placeId = null;
    });
}

export const DrivingRiderBookSeatComponent = {
  template: require('./book-seat.component.pug'),
  controller: DrivingRiderBookSeatCtrl
};
