import {
  getDatePickerOptions,
  getAddressComponentsFromGPlace
} from '../_core/common.helpers';
import {
  getEventActualDate,
  getOfferForSave
} from '../_core/events.helpers';

let _vm;

class EventRideOfferFormCtrl {
  constructor(
    $routeParams, $q, NotificationService, ReturnToService,
    EventService, RouteServiceV2, DrivingService
  ) {
    this.ngQSrvc = $q;
    this.ntfsSrvc = NotificationService;
    this.returnToSrvc = ReturnToService;
    this.eventSrvc = EventService;
    this.routeSrvc = RouteServiceV2;
    this.drivingSrvc = DrivingService;

    _vm = this;
    this.datePickerOptions = getDatePickerOptions();
    this.eventId = $routeParams.id;
    this.numbers10 = _.times(10, index => index + 1);
  }

  $onInit() {
    this.offer = {
      direction: 'roundtrip',
      seats: 1,
      returnSeats: 1,
      requirements: {}
    };
    this._loadEvent();
  }

  _loadEvent() {
    this.isLoading = true;
    return this.eventSrvc
      .getEventOne(this.eventId)
      .then(event => {
        let eventActualDate = getEventActualDate(event.dates);
        this.offer.date = moment(eventActualDate.fullStartDate).toDate();
        this.offer.time = moment(eventActualDate.fullStartDate).toDate();
        this.offer.returnDate = moment(eventActualDate.fullEndDate).toDate();
        this.offer.returnTime = moment(eventActualDate.fullEndDate).toDate();
        this.event = event;
      })
      .catch(err => this.ntfsSrvc.error('Unable to load event', err))
      .finally(() => this.isLoading = false);
  }

  setDirection(direction) {
    this.offer.direction = direction;
  }

  fromChanged() {
    let place = this.getPlace();
    _vm.offer.from = getAddressComponentsFromGPlace(place);
  }

  toChanged() {
    let place = this.getPlace();
    _vm.offer.to = getAddressComponentsFromGPlace(place);
  }

  createOffer() {
    let offerForSave = getOfferForSave(this.offer, this.event);
    if (offerForSave.date && moment(offerForSave.date).startOf('d') < moment().startOf('d')) {
      this.ntfsSrvc.warning('Driving date must be in the future');
      return;
    }
    if (offerForSave.returnDate && moment(offerForSave.returnDate).startOf('d') < moment().startOf('d')) {
      this.ntfsSrvc.warning('Driving return date must be in the future');
      return;
    }

    this.isSaving = true;
    this.ngQSrvc
      .resolve()
      .then(() => this._createRoute(offerForSave, offerForSave.from, offerForSave.to))
      .then(route => this._createDrivings(offerForSave, route))
      .then(() => {
        this.ntfsSrvc.info('Offer created successfully');
        this.returnToSrvc.navigateAndReturnTo(`/events/${this.eventId}`);
      })
      .catch(err => this.ntfsSrvc.warning('Unable to create offer', err))
      .finally(() => this.isSaving = false);
  }

  _createRoute(offer, originLoc, destLoc) {
    let route = {
      type: 'onetime',
      origin: originLoc,
      destination: destLoc,
      time: offer.time,
      returnTime: offer.returnTime,
      event: this.eventId
    };
    return this.routeSrvc.createRoute(route);
  }

  _createDrivings(offer, route) {
    let drivingData = {
      route: route.routeId,
      event: this.eventId,
      status: 'available',
      notes: offer.note
    };
    _.extend(drivingData, _.pick(offer, ['date', 'time', 'seats', 'requirements']));

    switch (offer.direction) {
      case 'there':
        drivingData.direction = 'dest';
        return this.drivingSrvc.createDriving(drivingData);
      case 'back':
        drivingData.direction = 'origin';
        return this.drivingSrvc.createDriving(drivingData);
      case 'roundtrip':
        return this.ngQSrvc.all([
          this.drivingSrvc.createDriving(this._exendDriving('dest', offer, drivingData)),
          this.drivingSrvc.createDriving(this._exendDriving('origin', offer, drivingData))
        ]);
    }
  }

  _exendDriving(direction, offer, drivingData) {
    drivingData = _.clone(drivingData);

    _.extend(drivingData, { direction });
    if (direction === 'origin') {
      _.extend(drivingData, {
        date: offer.returnDate,
        time: offer.returnTime,
        seats: offer.returnSeats
      });
    }

    return drivingData;
  }

  return() {
    this.returnToSrvc.navigateAndReturnTo();
  }
}

export const EventRideOfferFormComponent = {
  template: require('./ride-offer.component.pug'),
  controller: EventRideOfferFormCtrl
};
