export class NotificationService {
  constructor(toastr) {
    this.toastrSrv = toastr;
    toastr.options.closeButton = true;
  }

  notify(msg) {
    this.toastrSrv.success(msg);
  }

  info(msg) {
    this.toastrSrv.info(msg);
  }

  warning(msg, err) {
    _logError(err);
    this.toastrSrv.warning(this._getFullErrorMessage(msg, err));
  }

  error(msg, err) {
    _logError(err);
    this.toastrSrv.error(this._getFullErrorMessage(msg, err));
  }

  _getFullErrorMessage(msg, err) {
    let errMessage = _.get(err, 'message') || _.get(err, 'data.message') || '';
    return msg + '<br/>' + errMessage;
  }
}

function _logError(err) {
  if (_.isError(err)) {
    console.error(err);
  }
}
