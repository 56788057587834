import { ClipboardService } from './clipboard.service';
import { centsToDollarsFilter } from './cents-to-dollars.filter';
import { DollarsToCentsDirective } from './dollarts-to-cents.directive';
import { FocusMeDirective } from './focus-me.directive';
import { NavBarComponent } from './navbar.component';
import { ProgressIndicatorComponent } from './progress-indicator.component';
import { MapsDirectionsComponent } from './maps/directions.component';
import { MapsShapeComponent } from './maps/shape.component';
import { PartnerMapComponent } from './partners/map.component';
import { PartnerAreaComponent } from './partners/area.component';
import { PartnerAudienceComponent } from './partners/audience.component';
import { TzAutocompleteComponent } from './tz-autocomple.component';
import { FileUploaderComponent } from './file-uploader.component';
import { CardFormComponent } from './card-form.component';
import { CallFormComponent } from './call-form.component';

import './styles.styl';

angular
  .module('czApp._shared', [])
  .component('navBar', NavBarComponent)
  .service('ClipboardService', ClipboardService)
  .filter('centsToDollars', centsToDollarsFilter)
  .directive('dollarsToCents', DollarsToCentsDirective)
  .directive('focusMe', FocusMeDirective)
  .component('progressIndicator', ProgressIndicatorComponent)
  .component('mapsDirections', MapsDirectionsComponent)
  .component('mapsShape', MapsShapeComponent)
  .component('partnerMap', PartnerMapComponent)
  .component('partnerArea', PartnerAreaComponent)
  .component('partnerAudience', PartnerAudienceComponent)
  .component('tzAutocomplete', TzAutocompleteComponent)
  .component('fileUploader', FileUploaderComponent)
  .component('cardForm', CardFormComponent)
  .component('callForm', CallFormComponent);
