class RouteRegenerateButtonCtrl {
  constructor($q, $timeout, $route, NotificationService, RouteService) {
    this.ngQSrvc = $q;
    this.ngTimeoutSrvc = $timeout;
    this.ngRouteSrvc = $route;
    this.ntfsSrvc = NotificationService;
    this.routeSrvc = RouteService;
    this.cssClasses = ['btn-default'];
  }

  regenerate(generators) {
    this.runDate = new Date();
    this.runGenerators = generators;

    this.isSaving = true;
    let actions = _.map(this.routeIds, routeId => {
      return this.routeSrvc.regenerate({ routeId, generators }).$promise;
    });
    this.ngQSrvc
      .all(actions)
      .then(() => {
        this.ntfsSrvc.info('Route(s) regen process has started');
        return this._pollGeneratorStatuses();
      })
      .catch(err => this.ntfsSrvc.error('Unable to start regen route(s) process', err))
      .finally(() => this.isSaving = false);
  }

  _pollGeneratorStatuses() {
    let actions = _.map(this.routeIds, routeId => {
      return this.routeSrvc.get({ routeId, fields: 'generators' }).$promise;
    });
    return this.ngQSrvc
      .all(actions)
      .then(routes => {
        if (_.every(routes, route => this._isDone(route.generators))) {
          this.ntfsSrvc.info('Route(s) regen process has been finished');
          if (this.autoReloadPage) {
            this.ngRouteSrvc.reload();
          }
          return;
        }
        return this.ngTimeoutSrvc(1000).then(() => this._pollGeneratorStatuses());
      });
  }

  // eslint-disable-next-line complexity
  _isDone(gens) {
    let stopsLastRun = new Date(gens.stopsLastRun);
    let matchesLastRun = new Date(gens.matchesLastRun);
    let suggestedPlacesLastRun = new Date(gens.suggestedPlacesLastRun);
    let friendsLastRun = new Date(gens.friendsLastRun);

    let isDone = true;
    if (this.runGenerators === 'all' || this.runGenerators === 'stops') {
      isDone = isDone && this.runDate < stopsLastRun;
    }
    if (this.runGenerators === 'all' || this.runGenerators === 'matches') {
      isDone = isDone && this.runDate < matchesLastRun;
    }
    if (this.runGenerators === 'all' || this.runGenerators === 'matches' || this.runGenerators === 'sugPlaces') {
      isDone = isDone && this.runDate < suggestedPlacesLastRun;
    }
    if (this.runGenerators === 'all' || this.runGenerators === 'matches' || this.runGenerators === 'friends') {
      isDone = isDone && this.runDate < friendsLastRun;
    }
    return isDone;
  }
}

export const RouteRegenerateButtonComponent = {
  template: require('./regenerate-button.component.pug'),
  bindings: {
    routeIds: '<',
    cssClasses: '<',
    title: '<',
    autoReloadPage: '<',
    disabled: '<'
  },
  controller: RouteRegenerateButtonCtrl
};
