class HomeCtrl {
  constructor($location, Identity) {
    this.ngLocationSrvc = $location;
    this.Identity = Identity;
  }

  $onInit() {
    if (this.Identity.isAuthenticated() && this.Identity.hasRole('user')) {
      this.ngLocationSrvc.path('/partners');
    }
  }
}

export const HomeComponent = {
  template: require('./home.component.pug'),
  controller: HomeCtrl
};
