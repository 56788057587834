export class AuthSigninCtrl {
  constructor($location, AuthService, NotificationService) {
    this.ngLocationSrvc = $location;
    this.authSrvc = AuthService;
    this.ntfsSrvc = NotificationService;
  }

  signin() {
    this.isSaving = true;
    this.authSrvc
      .signin(this.email, this.password)
      .then(() => {
        this.ntfsSrvc.info('Welcome to Carzac');
        this.ngLocationSrvc.path('/');
      })
      .catch(err => this.ntfsSrvc.warning('Sign in failed!', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthSigninComponent = {
  template: require('./signin.component.pug'),
  controller: AuthSigninCtrl
};
