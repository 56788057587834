class PartnerAudienceCtrl {
  addAudienceDomain() {
    this.audience.domains.push({ value: '' });
  }

  removeAudienceDomain(domain) {
    _.remove(this.audience.domains, domain);
  }

  isAudienceValid() {
    this.audience.isValid = !this.audience.isCustom ||
                            (this.audience.domains.length > 0 &&
                            _.uniqBy(this.audience.domains, 'value').length === this.audience.domains.length);
    return this.audience.isValid;
  }
}

export const PartnerAudienceComponent = {
  template: require('./audience.component.pug'),
  bindings: {
    audience: '<',
    isEditable: '<',
    isSaving: '<',
    radioOneTitle: '@',
    radioTwoTitle: '@',
    hideOptions: '<'
  },
  controller: PartnerAudienceCtrl
};
