import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'spinkit/css/spinkit.css';
import 'toastr';
import 'toastr/build/toastr.css';
import 'lodash';
import 'moment';
import 'moment-timezone';

import 'angular';
import 'angular-route';
import 'angular-resource';
import 'angular-animate';

import 'angular-ui-bootstrap';
import 'angular-chart.js';
import 'ngmap';
import 'ngstorage';
import 'ng-table/bundles/ng-table.css';
import 'ng-table/bundles/ng-table.js';
import 'ng-file-upload';
import 'cleave.js/dist/cleave-angular.js';
