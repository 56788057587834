import { UserService } from './service';
import { UserServiceV2 } from './service.v2';
import { UserListComponent } from './list.component';
import { UserDetailsComponent } from './details.component';
import { UserAvatarComponent } from './avatar.component';

import './styles.styl';

angular
  .module('czApp.users', ['ngRoute'])
  .config(($routeProvider) => {
    $routeProvider
      .when('/users', {
        template: '<user-list></user-list>'
      })
      .when('/users/details', {
        template: '<user-details></user-details>'
      });
  })
  .service('UserService', UserService)
  .service('UserServiceV2', UserServiceV2)
  .component('userList', UserListComponent)
  .component('userDetails', UserDetailsComponent)
  .component('userAvatar', UserAvatarComponent);
