class FileUploaderCtrl {
  constructor($http, Upload, NotificationService) {
    this.httpSrvc = $http;
    this.uploadSrvc = Upload;
    this.ntfsSrvc = NotificationService;
  }

  $onChanges(changes) {
    let handler = _.get(changes, 'handler.currentValue');
    switch (handler) {
      case 'image':
        this.fileType = 'image/*';
        break;
      case 'activity-shedule':
        this.fileType = '.csv';
        break;
    }
  }

  uploadFile(files) {
    this.isUploading = true;
    this.uploadSrvc
      .upload({
        url: '/api/uploads/upload',
        method: 'POST',
        data:  _.extend({
          file: _.get(files, '0'),
          handler: this.handler
        }, this.params)
      })
      .then(res => {
        this.ntfsSrvc.info('File uploaded succesfully');
        this.onUploaded({ value: res.data });
      })
      .catch(err => this.ntfsSrvc.error('Unable to upload file', err))
      .finally(() => this.isUploading = false);
  }
}

export const FileUploaderComponent = {
  template: require('./file-uploader.component.pug'),
  controller: FileUploaderCtrl,
  bindings: {
    handler: '<',
    params: '<',
    onUploaded: '&'
  }
};
