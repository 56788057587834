class DrivingFormCtrl {
  constructor($location, $routeParams, NotificationService, DrivingService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.drivingSrvc = DrivingService;

    this.isLoading = true;
    this.dcKey = $routeParams.dcKey;
  }

  $onInit() {
    this._loadDriving();
  }

  _loadDriving() {
    if (!this.dcKey) {
      let now = moment().add(1, 'h');
      this.driving = {
        date: now.format('YYYY-MM-DD'),
        time: now.format('HH:00'),
        seats: 1,
        status: 'available'
      };
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.drivingSrvc
      .getDrivingOne({ dcKey: this.dcKey })
      .then(driving => {
        this.pristineDriving = _.cloneDeep(driving);
        this.driving = driving;
      })
      .catch(err => {
        this.ntfsSrvc.error('Unable to load driving', err);
        this.ngLocationSrvc.path('/drivings');
      })
      .finally(() => this.isLoading = false);
  }

  saveDriving() {
    this.isSaving = true;
    let fn = this.dcKey ?
      this.drivingSrvc.updateDriving.bind(this.drivingSrvc) :
      this.drivingSrvc.createDriving.bind(this.drivingSrvc);
    let drivingForSave;
    if (this.pristineDriving) {
      // eslint-disable-next-line no-unused-vars
      drivingForSave = _.fromPairs(_.differenceBy(_.entries(this.driving), _.entries(this.pristineDriving), ([key, val]) => val));
      drivingForSave.dcKey = this.dcKey;
    } else {
      drivingForSave = this.driving;
    }

    fn(drivingForSave)
      .then(() => {
        let message = `Driving ${this.driving.dcKey ? 'updated' : 'created'} successfully`;
        this.ntfsSrvc.info(message);
        this.ngLocationSrvc.path('/drivings');
      })
      .catch(err => this.ntfsSrvc.error('Error on saving driving', err))
      .finally(() => this.isSaving = false);
  }
}

export const DrivingFormComponent = {
  template: require('./form.component.pug'),
  controller: DrivingFormCtrl
};
