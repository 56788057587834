export class AuthService {
  constructor($http, $localStorage) {
    this.httpSrvc = $http;
    this.localStorageSrvc = $localStorage;
  }

  signup(email, password) {
    return this.httpSrvc.post('/api/register/local', { email, password });
  }

  signin(email, password) {
    return this.httpSrvc
      .post('/api/auth/local', { email, password })
      .then(res => this.localStorageSrvc.userInfo = JSON.stringify(res.data));
  }

  mobileLogin(rawPhoneNumber, countryAlphaCode) {
    return this.httpSrvc.post('/api/auth/mobile', { rawPhoneNumber, countryAlphaCode });
  }

  mobileLoginVerification(token, code) {
    return this.httpSrvc
      .post('/api/auth/mobile/verification', { token, code })
      .then(res => {
        this.localStorageSrvc.userInfo = JSON.stringify(res.data);
        return res;
      });
  }

  acceptTerms() {
    return this.httpSrvc.post('/api/auth/terms');
  }

  signout() {
    delete this.localStorageSrvc.userInfo;
    return Promise.resolve();
  }

  resetPassword(email) {
    return this.httpSrvc
      .put('/api/users/password/send', { email })
      .then(res => res.data);
  }

  verifyPassword(resetToken, password) {
    return this.httpSrvc
      .put('/api/users/password/verify', { resetToken, password })
      .then(res => res.data);
  }

  changeEmail(email) {
    return this.httpSrvc
      .put('/api/users/email/send', { email })
      .then(res => res.data);
  }

  verifyEmailToken(token) {
    return this.httpSrvc
      .put('/api/users/email/verify-token', { token })
      .then(res => res.data);
  }

  verifyEmailCode(token, code) {
    return this.httpSrvc
      .put('/api/users/email/verify-code', { token, code })
      .then(res => res.data);
  }

  changePhonenumber(rawPhoneNumber, countryAlphaCode) {
    return this.httpSrvc
      .put('/api/users/phonenumber/send', { rawPhoneNumber, countryAlphaCode })
      .then(res => res.data);
  }

  verifyPhonenumberCode(code) {
    return this.httpSrvc
      .put('/api/users/phonenumber/verify-code', { code })
      .then(res => res.data);
  }

  verifyPhonenumberToken(token) {
    return this.httpSrvc
      .put('/api/users/phonenumber/verify-token', { token })
      .then(res => res.data);
  }
}
