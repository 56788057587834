export class AuthMobileLoginUserDetailsCtrl {
  constructor($location, NotificationService, Identity, UserService) {
    this.ngLocationSrvc = $location;
    this.ntfsSrvc = NotificationService;
    this.identity = Identity;
    this.userSrvc = UserService;
  }

  $onInit() {
    this.isLoading = true;
    let identity = this.identity.getUser();
    this.userSrvc
      .getRegular({ id: identity.userId })
      .$promise
      .then(user => this.user = user)
      .catch(err => this.ntfsSrvc.warning('Unable to load user', err))
      .finally(() => this.isLoading = false);
  }

  send() {
    this.isSaving = true;
    this.user._id = this.user.userId;
    this.user
      .$updateRegular()
      .then(() => this.ngLocationSrvc.path('/terms'))
      .catch(err => this.ntfsSrvc.warning('Unable to update user', err))
      .finally(() => this.isSaving = false);
  }
}

export const AuthMobileLoginUserDetailsComponent = {
  template: require('./user-details.component.pug'),
  controller: AuthMobileLoginUserDetailsCtrl
};
