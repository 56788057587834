export class MessageService {
  constructor($http) {
    this.ngHttpSrvc = $http;
    this.messagesUrl = '/api/messages';
  }

  getMessages(params) {
    return this.ngHttpSrvc
      .get(this.messagesUrl, { params })
      .then(res => res.data);
  }

  getMessageCount(params) {
    let url = `${this.messagesUrl}/count`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data.count);
  }

  getMessageOne(params) {
    let url = `${this.messagesUrl}/${params._id}`;
    return this.ngHttpSrvc
      .get(url, { params })
      .then(res => res.data);
  }

  createMessage(message) {
    return this.ngHttpSrvc
      .post(this.messagesUrl, JSON.stringify(message))
      .then(res => res.data);
  }

  updateMessage(message) {
    let url = `${this.messagesUrl}/${message._id}`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(message))
      .then(res => res.data);
  }

  updateMessageStatus(message) {
    let url = `${this.messagesUrl}/${message._id}/status`;
    return this.ngHttpSrvc
      .put(url, JSON.stringify(message))
      .then(res => res.data);
  }

  deleteMessage(_id) {
    let url = `${this.messagesUrl}/${_id}`;
    return this.ngHttpSrvc.delete(url);
  }

  sendTestMessage(message) {
    let url = `${this.messagesUrl}/${message._id}/send`;
    return this.ngHttpSrvc
      .post(url)
      .then(res => res.data);
  }
}
